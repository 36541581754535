import './AnimatedBackground.scss';

const AnimatedBackground = () => (
  <div className='AnimatedBackground'>
    <ul className='AnimatedBackground_box-area'>
      <li className='AnimatedBackground_box-area_box box-1' />
      <li className='AnimatedBackground_box-area_box box-2' />
      <li className='AnimatedBackground_box-area_box box-3' />
      <li className='AnimatedBackground_box-area_box box-4' />
      <li className='AnimatedBackground_box-area_box box-5' />
      <li className='AnimatedBackground_box-area_box box-6' />
    </ul>
  </div>
);

export default AnimatedBackground;
