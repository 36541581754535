import './NameStrokeBg.scss';

const NameStrokeBg = () => (
  <div className='NameStrokeBg'>
    <svg
      id='nameBg'
      viewBox='0 0 513 55'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.748 3.87198V34.448H39.688V51.816H32.096V41H8.85201V51.816H1.26001V34.448H4.12001C5.43734 31.848 6.66801 29.0746 7.81201 26.128C8.95601 23.1466 9.97868 19.836 10.88 16.196C11.816 12.556 12.5613 8.44798 13.116 3.87198H34.748ZM19.356 10.424C19.044 12.816 18.5413 15.416 17.848 18.224C17.1893 20.9973 16.392 23.788 15.456 26.596C14.52 29.3693 13.4973 31.9866 12.388 34.448H26.896V10.424H19.356Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M52.255 23.84C52.255 24.4293 52.2203 25.1573 52.151 26.024C52.1163 26.8906 52.0643 27.7746 51.9949 28.676C51.9603 29.5773 51.9083 30.3747 51.839 31.068C51.7696 31.7613 51.7176 32.2466 51.6829 32.524L64.7869 12.608H74.0949V41H66.6069V29.664C66.6069 28.728 66.6416 27.7053 66.7109 26.596C66.7803 25.452 66.8496 24.3947 66.9189 23.424C67.0229 22.4186 67.0923 21.656 67.1269 21.136L54.0749 41H44.7669V12.608H52.255V23.84Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M107.621 41H99.8727V18.432H92.7487C92.2981 23.9786 91.6914 28.4506 90.9287 31.848C90.2007 35.2106 89.1781 37.672 87.8607 39.232C86.5434 40.7573 84.7754 41.52 82.5567 41.52C80.7541 41.52 79.2807 41.2426 78.1367 40.688V34.5C78.9341 34.8467 79.7661 35.02 80.6327 35.02C81.2567 35.02 81.8287 34.708 82.3487 34.084C82.8687 33.46 83.3541 32.3333 83.8047 30.704C84.2901 29.0746 84.7234 26.804 85.1047 23.892C85.4861 20.9453 85.8327 17.184 86.1447 12.608H107.621V41Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M120.024 41H111.652L119.296 29.768C117.84 29.1787 116.54 28.2253 115.396 26.908C114.287 25.556 113.732 23.7186 113.732 21.396C113.732 18.5533 114.807 16.3866 116.956 14.896C119.106 13.3706 121.862 12.608 125.224 12.608H138.432V41H130.684V30.444H126.42L120.024 41ZM121.324 21.448C121.324 22.6266 121.792 23.5626 122.728 24.256C123.664 24.9146 124.878 25.244 126.368 25.244H130.684V18.068H125.38C123.994 18.068 122.971 18.4146 122.312 19.108C121.654 19.7666 121.324 20.5466 121.324 21.448Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M154.281 12.608V23.528H165.097V12.608H172.845V41H165.097V29.3H154.281V41H146.533V12.608H154.281Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M192.61 12.036C196.424 12.036 199.336 12.868 201.346 14.532C203.392 16.1613 204.414 18.6746 204.414 22.072V41H199.006L197.498 37.152H197.29C196.077 38.6773 194.794 39.7866 193.442 40.48C192.09 41.1733 190.236 41.52 187.878 41.52C185.348 41.52 183.25 40.792 181.586 39.336C179.922 37.8453 179.09 35.5746 179.09 32.524C179.09 29.508 180.148 27.2893 182.262 25.868C184.377 24.412 187.549 23.6146 191.778 23.476L196.718 23.32V22.072C196.718 20.5813 196.32 19.4893 195.522 18.796C194.76 18.1026 193.685 17.756 192.298 17.756C190.912 17.756 189.56 17.964 188.242 18.38C186.925 18.7613 185.608 19.2466 184.29 19.836L181.742 14.584C183.268 13.7866 184.949 13.1626 186.786 12.712C188.658 12.2613 190.6 12.036 192.61 12.036ZM193.702 27.948C191.206 28.0173 189.473 28.468 188.502 29.3C187.532 30.132 187.046 31.224 187.046 32.576C187.046 33.7546 187.393 34.604 188.086 35.124C188.78 35.6093 189.681 35.852 190.79 35.852C192.454 35.852 193.858 35.3666 195.002 34.396C196.146 33.3906 196.718 31.9866 196.718 30.184V27.844L193.702 27.948Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M239.745 9.90398C236.729 9.90398 234.423 11.0306 232.829 13.284C231.234 15.5373 230.437 18.6227 230.437 22.54C230.437 26.492 231.165 29.56 232.621 31.744C234.111 33.8933 236.486 34.968 239.745 34.968C241.27 34.968 242.778 34.7946 244.269 34.448C245.794 34.1013 247.441 33.616 249.209 32.992V39.596C247.579 40.2547 245.967 40.74 244.373 41.052C242.778 41.364 240.993 41.52 239.017 41.52C235.203 41.52 232.049 40.74 229.553 39.18C227.091 37.5853 225.271 35.3666 224.093 32.524C222.914 29.6466 222.325 26.3013 222.325 22.488C222.325 18.744 223.001 15.4333 224.353 12.556C225.705 9.67865 227.663 7.42531 230.229 5.79598C232.829 4.16665 236.001 3.35198 239.745 3.35198C241.582 3.35198 243.419 3.59465 245.257 4.07998C247.129 4.53065 248.914 5.15465 250.613 5.95198L248.065 12.348C246.678 11.6893 245.274 11.1173 243.853 10.632C242.466 10.1466 241.097 9.90398 239.745 9.90398Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M279.978 18.432H270.67V41H262.922V18.432H253.614V12.608H279.978V18.432Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M296.408 12.088C300.326 12.088 303.428 13.2146 305.716 15.468C308.004 17.6866 309.148 20.8586 309.148 24.984V28.728H290.844C290.914 30.912 291.555 32.628 292.768 33.876C294.016 35.124 295.732 35.748 297.916 35.748C299.754 35.748 301.418 35.5746 302.908 35.228C304.399 34.8466 305.942 34.2747 307.536 33.512V39.492C306.15 40.1853 304.676 40.688 303.116 41C301.591 41.3467 299.736 41.52 297.552 41.52C294.71 41.52 292.196 41 290.012 39.96C287.828 38.8853 286.112 37.2733 284.864 35.124C283.616 32.9747 282.992 30.2706 282.992 27.012C282.992 23.684 283.547 20.928 284.656 18.744C285.8 16.5253 287.378 14.8613 289.388 13.752C291.399 12.6426 293.739 12.088 296.408 12.088ZM296.46 17.6C294.97 17.6 293.722 18.0853 292.716 19.056C291.746 20.0266 291.174 21.5346 291 23.58H301.868C301.834 21.8466 301.383 20.4253 300.516 19.316C299.65 18.172 298.298 17.6 296.46 17.6Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M336.179 12.452C341.102 12.868 344.863 14.376 347.463 16.976C350.063 19.5413 351.363 22.8173 351.363 26.804C351.363 30.7907 350.098 34.084 347.567 36.684C345.036 39.284 341.24 40.792 336.179 41.208V53.48H329.003V41.208C324.115 40.7573 320.336 39.232 317.667 36.632C315.032 34.032 313.715 30.756 313.715 26.804C313.715 22.748 314.946 19.4373 317.407 16.872C319.903 14.272 323.768 12.7986 329.003 12.452V1.47998H336.179V12.452ZM329.003 18.276C326.75 18.588 324.964 19.524 323.647 21.084C322.33 22.644 321.671 24.5506 321.671 26.804C321.671 29.1266 322.312 31.068 323.595 32.628C324.878 34.1533 326.68 35.0547 329.003 35.332V18.276ZM336.179 35.332C338.502 35.0547 340.287 34.1533 341.535 32.628C342.783 31.068 343.407 29.1266 343.407 26.804C343.407 24.5506 342.766 22.6613 341.483 21.136C340.2 19.576 338.432 18.6573 336.179 18.38V35.332Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M369.431 12.036C373.244 12.036 376.156 12.868 378.167 14.532C380.212 16.1613 381.235 18.6746 381.235 22.072V41H375.827L374.319 37.152H374.111C372.897 38.6773 371.615 39.7866 370.263 40.48C368.911 41.1733 367.056 41.52 364.699 41.52C362.168 41.52 360.071 40.792 358.407 39.336C356.743 37.8453 355.911 35.5746 355.911 32.524C355.911 29.508 356.968 27.2893 359.083 25.868C361.197 24.412 364.369 23.6146 368.599 23.476L373.539 23.32V22.072C373.539 20.5813 373.14 19.4893 372.343 18.796C371.58 18.1026 370.505 17.756 369.119 17.756C367.732 17.756 366.38 17.964 365.063 18.38C363.745 18.7613 362.428 19.2466 361.111 19.836L358.563 14.584C360.088 13.7866 361.769 13.1626 363.607 12.712C365.479 12.2613 367.42 12.036 369.431 12.036ZM370.523 27.948C368.027 28.0173 366.293 28.468 365.323 29.3C364.352 30.132 363.867 31.224 363.867 32.576C363.867 33.7546 364.213 34.604 364.907 35.124C365.6 35.6093 366.501 35.852 367.611 35.852C369.275 35.852 370.679 35.3666 371.823 34.396C372.967 33.3906 373.539 31.9866 373.539 30.184V27.844L370.523 27.948Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M396.913 12.608V23.528H407.729V12.608H415.477V41H407.729V29.3H396.913V41H389.165V12.608H396.913Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M449.387 26.752C449.387 31.4666 448.139 35.1066 445.643 37.672C443.182 40.2373 439.819 41.52 435.555 41.52C432.92 41.52 430.563 40.948 428.483 39.804C426.438 38.66 424.826 36.996 423.647 34.812C422.468 32.5933 421.879 29.9066 421.879 26.752C421.879 22.0373 423.11 18.4146 425.571 15.884C428.032 13.3533 431.412 12.088 435.711 12.088C438.38 12.088 440.738 12.66 442.783 13.804C444.828 14.948 446.44 16.612 447.619 18.796C448.798 20.9453 449.387 23.5973 449.387 26.752ZM429.783 26.752C429.783 29.56 430.234 31.692 431.135 33.148C432.071 34.5693 433.579 35.28 435.659 35.28C437.704 35.28 439.178 34.5693 440.079 33.148C441.015 31.692 441.483 29.56 441.483 26.752C441.483 23.944 441.015 21.8466 440.079 20.46C439.178 19.0386 437.687 18.328 435.607 18.328C433.562 18.328 432.071 19.0386 431.135 20.46C430.234 21.8466 429.783 23.944 429.783 26.752Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M480.958 20.044C480.958 21.5693 480.473 22.8693 479.502 23.944C478.566 25.0186 477.162 25.712 475.29 26.024V26.232C477.266 26.4746 478.844 27.1506 480.022 28.26C481.236 29.3693 481.842 30.7906 481.842 32.524C481.842 34.1533 481.409 35.6093 480.542 36.892C479.71 38.1746 478.376 39.18 476.538 39.908C474.701 40.636 472.292 41 469.31 41H455.79V12.608H469.31C471.529 12.608 473.505 12.8506 475.238 13.336C477.006 13.7866 478.393 14.5493 479.398 15.624C480.438 16.6986 480.958 18.172 480.958 20.044ZM473.106 20.668C473.106 18.9346 471.737 18.068 468.998 18.068H463.538V23.684H468.114C469.744 23.684 470.974 23.4586 471.806 23.008C472.673 22.5226 473.106 21.7426 473.106 20.668ZM473.834 32.108C473.834 30.9987 473.401 30.2013 472.534 29.716C471.668 29.196 470.368 28.936 468.634 28.936H463.538V35.644H468.79C470.246 35.644 471.442 35.384 472.378 34.864C473.349 34.3093 473.834 33.3907 473.834 32.108Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M500.142 12.036C503.955 12.036 506.867 12.868 508.878 14.532C510.923 16.1613 511.945 18.6746 511.945 22.072V41H506.538L505.03 37.152H504.822C503.608 38.6773 502.326 39.7866 500.974 40.48C499.622 41.1733 497.767 41.52 495.41 41.52C492.879 41.52 490.781 40.792 489.117 39.336C487.453 37.8453 486.621 35.5746 486.621 32.524C486.621 29.508 487.679 27.2893 489.793 25.868C491.908 24.412 495.08 23.6146 499.31 23.476L504.25 23.32V22.072C504.25 20.5813 503.851 19.4893 503.053 18.796C502.291 18.1026 501.216 17.756 499.829 17.756C498.443 17.756 497.091 17.964 495.773 18.38C494.456 18.7613 493.139 19.2466 491.822 19.836L489.273 14.584C490.799 13.7866 492.48 13.1626 494.318 12.712C496.19 12.2613 498.131 12.036 500.142 12.036ZM501.233 27.948C498.737 28.0173 497.004 28.468 496.034 29.3C495.063 30.132 494.578 31.224 494.578 32.576C494.578 33.7546 494.924 34.604 495.617 35.124C496.311 35.6093 497.212 35.852 498.322 35.852C499.986 35.852 501.39 35.3666 502.534 34.396C503.678 33.3906 504.25 31.9866 504.25 30.184V27.844L501.233 27.948Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default NameStrokeBg;
