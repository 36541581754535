import { LOCALES } from '../translations/constants';

const data = {
  [LOCALES.ENGLISH]: [
    {
      institution: 'Sadigh Group',
      location: 'Nice, France',
      title: 'Lead Full-Stack Developer',
      description:
        'I am in charge of the development of a computer performance monitoring service in C# (Windows), Golang (Linux) and Swift (MacOS), multiple microservices and two web platforms. I also regularly contribute to the development of various projects with Javascript and Typescript: web platforms (React, Redux), mobile application (React Native, Redux), microservices (Node.js, Express.js, MongoDB), AWS lambda functions.',
      date: 'April 2021 - Today',
      tag: 'work',
      studies: false,
    },
    {
      institution: '53JS',
      location: 'Nice, France',
      title: 'Full-Stack JavaScript Developer',
      description:
        'I worked on a web platform offering videos on streaming, VOD and real-time video and chat communication. (React, Node.js, Express.js, MongoDB, Redux, WebRTC, WebSockets)',
      date: 'December 2019 - December 2020',
      tag: 'work',
      studies: false,
    },
    {
      institution: 'Trade and Services Business School (ICS)',
      location: 'Nice, France',
      title: 'Web and Mobile Application Engineering',
      description: "Bachelor's level professional degree (Bac+3)",
      date: 'November 2019 - November 2020',
      tag: 'studies',
      studies: true,
    },
    {
      institution: 'Rheveo',
      location: 'Sophia Antipolis, France',
      title: 'Front-End Developer',
      description:
        "I took part in the development of the UI of a web platform that uses Artificial Intelligence and Psychology to improve the performance of the organizations through their collaborators' engagement. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: 'June 2019 - July 2019',
      tag: 'work',
      studies: false,
    },
    {
      institution: 'Trade and Services Business School (ICS)',
      location: 'Nice, France',
      title: 'Web and Mobile Web Development',
      description: 'Professional degree (Bac+2)',
      date: 'March 2019 - October 2019',
      tag: 'studies',
      studies: true,
    },
    {
      institution: 'Inti Formation',
      location: 'Sophia Antipolis, France',
      title: 'Java/JEE Software Development',
      description: 'Java/JEE Research and Development training',
      date: 'December 2018 - March 2019',
      tag: 'studies',
      studies: true,
    },
    {
      institution: 'University of Nice Sophia Antipolis',
      location: 'Nice, France',
      title: "Master's degree in Hispanic and Latin American Studies",
      description:
        'Research oriented master in Hispanic and Latin American Languages, Literatures and Linguistics',
      date: 'August 2016 - September 2018',
      tag: 'studies',
      studies: true,
    },
    {
      institution: 'TELUS International Europe',
      location: 'Sofia, Bulgaria',
      title: 'Fitbit Technical Support Specialist',
      description:
        'Technical support specialist for the American company Fitbit. Support in Spanish, English and French via phone, email and over the social networks.',
      date: 'September 2015 - August 2016',
      tag: 'work',
      studies: false,
    },
    {
      institution: 'Sofia University "St. Kliment Ohridski"',
      location: 'Sofia, Bulgaria',
      title: "Bachelor's degree in Hispanic and Latin American Studies (Bac+4)",
      description: 'Spanish Translation and Teaching',
      date: 'October 2011 - September 2015',
      tag: 'studies',
      studies: true,
    },
    {
      institution: 'University of Valencia',
      location: 'Valencia, Spain',
      title: 'Hispanic and Latin American Studies',
      description: 'Spanish Linguistics (Erasmus)',
      date: 'September 2013 - June 2014',
      tag: 'studies',
      studies: true,
    },
  ],
  [LOCALES.FRENCH]: [
    {
      institution: 'Sadigh Group',
      location: 'Nice, France',
      title: 'Lead Développeuse Full-Stack',
      description:
        "Je suis en charge du développement d'un service de monitoring des performances des ordinateurs en C# (Windows), Golang (Linux) et Swift (MacOS), de plusieurs microservices et deux plateformes web. Je participe régulièrement au développement de différents projets en Javascript et Typescript: des plateformes web (React, Redux), une application mobile (React Native, Redux), plusieurs microservices (Node.js, Express.js, MongoDB), des fonctions AWS Lambda",
      date: 'Avril 2021 - Aujourd\'hui',
      tag: 'emploi',
      studies: false,
    },
    {
      institution: '53JS',
      location: 'Nice, France',
      title: 'Développeuse Javascript Full-Stack',
      description:
        "J'ai participé au développement d'une plateforme de streaming vidéo, de VOD et de communication vidéo en temps réel. (React, Node.js, Express.js, MongoDB, Redux, WebRTC, WebSockets)",
      date: 'Décembre 2019 - Décembre 2020',
      tag: 'emploi',
      studies: false,
    },
    {
      institution: 'Institut du Commerce et des Services (ICS)',
      location: 'Nice, France',
      title: "Concepteur Dévelopeur d'Applications",
      description: 'Titre Professionnel (niveau Bac+3)',
      date: 'Novembre 2019 - Novembre 2020',
      tag: 'études',
      studies: true,
    },
    {
      institution: 'Rheveo',
      location: 'Sophia Antipolis, France',
      title: 'Développeuse Front-End',
      description:
        "J'ai participé au développement de l'Interface Utilisateur d'une plateforme web qui réunit Intelligence Artificielle et Psychologie, afin d'évaluer et d'améliorer la performance des entreprises à travers l'engagement de leurs collaborateurs. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)",
      date: 'Juin 2019 - Juillet 2019',
      tag: 'emploi',
      studies: false,
    },
    {
      institution: 'Institut du Commerce et des Services (ICS)',
      location: 'Nice, France',
      title: 'Développeur Web et Web Mobile',
      description: 'Titre Professionnel (niveau Bac+2)',
      date: 'Mars 2019 - Octobre 2019',
      tag: 'études',
      studies: true,
    },
    {
      institution: 'Inti Formation',
      location: 'Sophia Antipolis, France',
      title: 'Développeur Java/JEE',
      description: 'Formation en développement de logiciels en Java/JEE',
      date: 'Décembre 2018 - Mars 2019',
      tag: 'études',
      studies: true,
    },
    {
      institution: 'Université de Nice Sophia Antipolis',
      location: 'Nice, France',
      title: 'Master en Études Hispaniques et Hispano-Américaines',
      description:
        '(Mention Très Bien) Master Recherche en Langues, Litteratures et Linguistiques Hispaniques et Hispano-Amériacaines',
      date: 'Août 2016 - Septembre 2018',
      tag: 'études',
      studies: true,
    },
    {
      institution: 'TELUS International Europe',
      location: 'Sofia, Bulgarie',
      title: 'Spécialiste en Support Technique',
      description:
        "Spécialiste en Support Technique pour l'entreprise Américaine Fitbit. Support en espagnol, anglais et français par téléphone, par email et sur les réseaux sociaux.",
      date: 'Septembre 2015 - Août 2016',
      tag: 'emploi',
      studies: false,
    },
    {
      institution: 'Université de Sofia "St. Kliment Ohridski"',
      location: 'Sofia, Bulgarie',
      title: 'Licence en Études Hispaniques et Hispano-Américaines (Bac+4)',
      description:
        "Diplôme de traducteur et professeur d'espagnol (Obtenu avec les félicitations du jury).",
      date: 'Octobre 2011 - Septembre 2015',
      tag: 'études',
      studies: true,
    },
    {
      institution: 'Université de Valence',
      location: 'Valence, Espagne',
      title: 'Études Hispaniques et Hispano-Américaines',
      description: 'Erasmus',
      date: 'Septembre 2013 - Juin 2014',
      tag: 'études',
      studies: true,
    },
  ],
  [LOCALES.SPANISH]: [
    {
      institution: 'Sadigh Group',
      location: 'Niza, France',
      title: 'Lead Desarrolladora Full-Stack',
      description:
          "Me ocupo del desarrollo de un servicio de monitoreo del rendimiento de los ordenadores en C# (Windows), Golang (Linux) y Swift (MacOS), varios microservicios y dos plataformes web. Participo de manera regular en el desarrollo de varios proyectos en Javascript y Typescript: de plataformas web (React, Redux), una aplicaciòn móvil (React Native, Redux), varios microservicios (Node.js, Express.js, MongoDB), funciones AWS Lambda",
      date: 'Abril 2021 - Hoy',
      tag: 'empleo',
      studies: false,
    },
    {
      institution: '53JS',
      location: 'Niza, Francia',
      title: 'Desarrolladora JavaScript Full-Stack',
      description:
        'Participé en el diseño y el desarrollo de una plataforma web de streaming de vídeo, VOD y comunicación por vídeo en tiempo real. (React, Node.js, Express.js, MongoDB, Redux, WebRTC, WebSockets)',
      date: 'Dicicembre 2019 - Diciembre 2020',
      tag: 'empleo',
      studies: false,
    },
    {
      institution: 'Instituto del Comercio y de los Servicios (ICS)',
      location: 'Niza, Francia',
      title: 'Desarrollo y Diseño de Aplicaciones Web y Móviles',
      description: 'Título profesional (nivel Grado)',
      date: 'Noviembre 2019 - Noviembre 2020',
      tag: 'estudios',
      studies: true,
    },
    {
      institution: 'Rheveo',
      location: 'Sophia Antipolis, Francia',
      title: 'Desarrolladora Front-End',
      description:
        'Participé en el desarrollo de la Interfaz Usuario de una plataforma web que une Inteligencia Artificial y Psicología con el fin de evaluar y mejorar el rendimiento de las empresas a través del compromiso de sus colaboradores. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)',
      date: 'Junio 2019 - Julio 2019',
      tag: 'empleo',
      studies: false,
    },
    {
      institution: 'Instituto del Comercio y de los Servicios (ICS)',
      location: 'Niza, Francia',
      title: 'Dessarollo Web y Móvil',
      description: 'Título Profesional (BAC+2)',
      date: 'Marzo 2019 - Octubre 2019',
      tag: 'estudios',
      studies: true,
    },
    {
      institution: 'Inti Formation',
      location: 'Sophia Antipolis, Francia',
      title: 'Desarrollador Java/JEE',
      description: 'Formación en desarrollo de software en Java/JEE',
      date: 'Diciembre 2018 - Marzo 2019',
      tag: 'estudios',
      studies: true,
    },
    {
      institution: 'Universidad de Niza Sophia Antipolis',
      location: 'Niza, Francia',
      title: 'Master en Estudios Hispánicos e Hispano-Americanos',
      description:
        'Master de Investigación en Lenguas, Litteraturas y Linguística Hispánicas e Hispano-Americanas',
      date: 'Agosto 2016 - Septiembre 2018',
      tag: 'estudios',
      studies: true,
    },
    {
      institution: 'TELUS International Europe',
      location: 'Sofía, Bulgaria',
      title: 'Especialista en Soporte Técnico (Fitbit)',
      description:
        'Especialista en Soporte Técnico para la empresa Americana Fitbit. Soporte en español, inglés y francés por teléfono, por correo electrónico y en las redes sociales.',
      date: 'Septiembre 2015 - Agosto 2016',
      tag: 'empleo',
      studies: false,
    },
    {
      institution: 'Universidad de Sofía "St. Kliment Ohridski"',
      location: 'Sofía, Bulgaria',
      title: 'Grado en Estudios Hispánicos e Hispano-Americanos',
      description: 'Diploma de traductor y profesor de español.',
      date: 'Octubre 2011 - Septiembre 2015',
      tag: 'estudios',
      studies: true,
    },
    {
      institution: 'Universidad de Vanlencia',
      location: 'Valencia, España',
      title: 'Estudios Hispánicos e Hispano-Americanos',
      description: 'Erasmus',
      date: 'Septiembre 2013 - Junio 2014',
      tag: 'estudios',
      studies: true,
    },
  ],
  [LOCALES.BULGARIAN]: [
    {
      institution: 'Sadigh Group',
      location: 'Ница, Франция',
      title: 'Lead Full-Stack Разработчик',
      description:
        "Отговарям за разработването на услуга за мониторинг на производителността на компютрите със C# (Windows) Golang (Linux) и Swift (MacOS), няколко микроуслуги и две уеб платформи. Редовно участвам в разработването на различни проекти с Javascript и Typescript: уеб платформи (React, Redux), мобилно приложение (React Native, Redux), микроуслуги (Node.js, Express.js, MongoDB), AWS Lambda функции",
      date: 'Април 2021 - Настояще',
      tag: 'работа',
      studies: false,
    },
    {
      institution: '53JS',
      location: 'Ница, Франция',
      title: 'Full-Stack JavaScript Разработчик',
      description:
        'Участвах в разработването на уеб платформа за видео стрийминг, видео споделяне и видео комуникация в реално време. (React, Node.js, Express.js, MongoDB, Redux, WebRTC, WebSockets)',
      date: 'Декември 2019 - Декември 2020',
      tag: 'работа',
      studies: false,
    },
    {
      institution: 'Институт за Търговия и Услуги (ICS)',
      location: 'Ница, Франция',
      title: 'Разработване и Дизайн на Уеб и Мобилни Апликации',
      description: 'Професионално ниво Бакалавър ',
      date: 'Ноември 2019 - Ноември 2020',
      tag: 'образование',
      studies: true,
    },
    {
      institution: 'Rheveo',
      location: 'Софиа-Антиполис, Франция',
      title: 'Front-End Разработчик',
      description:
        'Участвах в разработването на потребителски интерфейс за уеб платформа, която съчетава Изкуствен Интелект и Психология с цел оценка и подобряване на производителността на предприятията чрез ангажираността на служителите. (Vue.js, Element UI, JavaScript, CSS/Sass, Bootstrap, HTML)',
      date: 'Юни 2019 - Юли 2019',
      tag: 'работа',
      studies: false,
    },
    {
      institution: 'Институт за Търговия и Услуги (ICS)',
      location: 'Ница, Франция',
      title: 'Уеб Разработчик',
      description: 'Професионална квалификация',
      date: 'Март 2019 - Октомври 2019',
      tag: 'образование',
      studies: true,
    },
    {
      institution: 'Inti Formation',
      location: 'Софиа-Антиполис, Франция',
      title: 'Разработване на Софтуер с Java/JEE',
      description: 'Интензивно обучение',
      date: 'Декември 2018 - Март 2019',
      tag: 'образование',
      studies: true,
    },
    {
      institution: 'Университет Nice Sophia Antipolis',
      location: 'Ница, Франция',
      title: 'Магистърска степен по Испанистика',
      description: 'Магистърска степен',
      date: 'Август 2016 - Септември 2018',
      tag: 'образование',
      studies: true,
    },
    {
      institution: 'TELUS International Europe',
      location: 'София, България',
      title: 'Technical Support Specialist (Fitbit)',
      description:
        'Technical Support Specialist с испански, френски и английски език (телефон, имейл, социални мрежи).',
      date: 'Септември 2015 - Август 2016',
      tag: 'работа',
      studies: false,
    },
    {
      institution: 'Софийски Университет "Св. Климент Охридски"',
      location: 'София, България',
      title: 'Бакалавърска степен по Испанска филология',
      description: 'Диплома за преводач, учител по испански език.',
      date: 'Октомври 2011 - Септември 2015',
      tag: 'образование',
      studies: true,
    },
    {
      institution: 'Universitat de València',
      location: 'Валенсия, Испания',
      title: 'Испанистика',
      description: 'Обучение по програма Еразъм',
      date: 'Септември 2013 - Юни 2014',
      tag: 'образование',
      studies: true,
    },
  ],
};

export default data;
