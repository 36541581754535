import en from './en';
import fr from './fr';
import es from './es';
import bg from './bg';

const messages = {
  ...en,
  ...fr,
  ...es,
  ...bg,
};

export default messages;
