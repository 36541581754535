import { LOCALES } from '../constants';

const bg = {
  [LOCALES.BULGARIAN]: {
    menu: {
      home: 'Начало',
      about: 'За мен',
      skills: 'Умения',
      projects: 'Проекти',
      experience: 'Опит',
      contact: 'Контакт',
      resume: 'CV',
    },
    home: {
      presentation: 'Здравей! Казвам се ',
      profession: 'и съм ',
      contactBtn: 'Свържи се с мен',
    },
    about: {
      heading: 'За мен',
      presentation:
        'Здравей! 👋🏻 Казвам се Диляна и съм full-stack разработчик. Живея в Ница, Франция',
      info1:
        'Моята страст са програмирането 💻 и чуждите езици 🗺️ . Обичам да създавам естетически и функционални уеб и мобилни апликации и се стремя да пиша качествен, добре структуриран код, както и постоянно да се усъвършенствам.',
      info2:
        'Говоря свободно френски 🇫🇷 , английски 🇬🇧 , испански 🇪🇸 и български 🇧🇬. Имам Магистърска степен по',
      mastersDegree: '"Испанистика и Ибероамериканистика" ',
      info3: 'и професионално ниво Бакалавър по',
      bachelorsDegree: '"Разработване и Дизайн на Уеб и Мобилни Апликации".',
      info4:
        '"Храня се" главно с код и добро кафе ☕ . Силно мотивирана съм, обичам да усвоявам нови умения 📚 , да съм в крак с най-новите технологии и да ги прилагам на практика чрез различни проекти 👩🏼‍🔬 .',
      interestsHeading: 'Интереси: ',
      info5:
        'програмиране, обучение, личностно развитие, чужди езици, видео игри, ретро гейминг, бордни игри и електроника. Накратко, аз съм "geek" 👩🏼‍💻 .',
    },
    skills: {
      heading: 'Умения',
    },
    projects: {
      heading: 'Проекти',
      technologies: 'Технологии',
      description: 'Описание',
      visitSiteBtn: 'Посети сайта',
      seeMoreBtn: 'Виж повече',
      watchDemoBtn: 'Гледай Демо',
      checkGithubBtn: 'Виж Github',
    },
    experience: {
      heading: 'Опит',
    },
    contact: {
      heading: 'Свържете се с мен',
      form: {
        name: 'Име и Фамилия',
        email: 'Email Адрес',
        subject: 'Тема',
        message: 'Съобщение',
        sendButton: 'Изпрати',
        confirmation: 'Изпратено!',
        error: 'Възникна грешка.',
      },
    },
    flags: {
      british: 'британско знаме',
      bulgarian: 'българско знаме',
      french: 'френско знаме',
      spanish: 'испанско знаме',
    },
  },
};

export default bg;
