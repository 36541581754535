import './DeveloperAnimationEn.scss';

const DeveloperAnimationEn = () => (
  <div className='DeveloperAnimationEn'>
    <svg
      id='developerEn'
      width='526'
      height='54'
      viewBox='0 0 526 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.1 17.9088H11.25V39.8588H4.15V17.9088H1V12.1588H4.15V10.7588C4.15 7.35882 5.11667 4.85882 7.05 3.25882C8.98333 1.65882 11.9 0.908823 15.8 1.00882V6.90882C14.1 6.87549 12.9167 7.15882 12.25 7.75882C11.5833 8.35882 11.25 9.44216 11.25 11.0088V12.1588H16.1V17.9088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M46.7863 12.1588V39.8588H39.7363V36.3588C38.8363 37.5588 37.653 38.5088 36.1863 39.2088C34.753 39.8755 33.1863 40.2088 31.4863 40.2088C29.3197 40.2088 27.403 39.7588 25.7363 38.8588C24.0697 37.9255 22.753 36.5755 21.7863 34.8088C20.853 33.0088 20.3863 30.8755 20.3863 28.4088V12.1588H27.3863V27.4088C27.3863 29.6088 27.9363 31.3088 29.0363 32.5088C30.1363 33.6755 31.6363 34.2588 33.5363 34.2588C35.4697 34.2588 36.9863 33.6755 38.0863 32.5088C39.1863 31.3088 39.7363 29.6088 39.7363 27.4088V12.1588H46.7863Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M60.693 2.85882V39.8588H53.693V2.85882H60.693Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M74.609 2.85882V39.8588H67.609V2.85882H74.609Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M102.825 19.0588V24.9588H81.625V19.0588H102.825Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M121.275 40.3088C119.009 40.3088 116.975 39.9088 115.175 39.1088C113.375 38.2755 111.942 37.1588 110.875 35.7588C109.842 34.3588 109.275 32.8088 109.175 31.1088H116.225C116.359 32.1755 116.875 33.0588 117.775 33.7588C118.709 34.4588 119.859 34.8088 121.225 34.8088C122.559 34.8088 123.592 34.5422 124.325 34.0088C125.092 33.4755 125.475 32.7922 125.475 31.9588C125.475 31.0588 125.009 30.3922 124.075 29.9588C123.175 29.4922 121.725 28.9922 119.725 28.4588C117.659 27.9588 115.959 27.4422 114.625 26.9088C113.325 26.3755 112.192 25.5588 111.225 24.4588C110.292 23.3588 109.825 21.8755 109.825 20.0088C109.825 18.4755 110.259 17.0755 111.125 15.8088C112.025 14.5422 113.292 13.5422 114.925 12.8088C116.592 12.0755 118.542 11.7088 120.775 11.7088C124.075 11.7088 126.709 12.5422 128.675 14.2088C130.642 15.8422 131.725 18.0588 131.925 20.8588H125.225C125.125 19.7588 124.659 18.8922 123.825 18.2588C123.025 17.5922 121.942 17.2588 120.575 17.2588C119.309 17.2588 118.325 17.4922 117.625 17.9588C116.959 18.4255 116.625 19.0755 116.625 19.9088C116.625 20.8422 117.092 21.5588 118.025 22.0588C118.959 22.5255 120.409 23.0088 122.375 23.5088C124.375 24.0088 126.025 24.5255 127.325 25.0588C128.625 25.5922 129.742 26.4255 130.675 27.5588C131.642 28.6588 132.142 30.1255 132.175 31.9588C132.175 33.5588 131.725 34.9922 130.825 36.2588C129.959 37.5255 128.692 38.5255 127.025 39.2588C125.392 39.9588 123.475 40.3088 121.275 40.3088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M146.071 17.9088V31.3088C146.071 32.2422 146.288 32.9255 146.721 33.3588C147.188 33.7588 147.955 33.9588 149.021 33.9588H152.271V39.8588H147.871C141.971 39.8588 139.021 36.9922 139.021 31.2588V17.9088H135.721V12.1588H139.021V5.30882H146.071V12.1588H152.271V17.9088H146.071Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M155.506 25.9088C155.506 23.1088 156.056 20.6255 157.156 18.4588C158.29 16.2922 159.806 14.6255 161.706 13.4588C163.64 12.2922 165.79 11.7088 168.156 11.7088C170.223 11.7088 172.023 12.1255 173.556 12.9588C175.123 13.7922 176.373 14.8422 177.306 16.1088V12.1588H184.356V39.8588H177.306V35.8088C176.406 37.1088 175.156 38.1922 173.556 39.0588C171.99 39.8922 170.173 40.3088 168.106 40.3088C165.773 40.3088 163.64 39.7088 161.706 38.5088C159.806 37.3088 158.29 35.6255 157.156 33.4588C156.056 31.2588 155.506 28.7422 155.506 25.9088ZM177.306 26.0088C177.306 24.3088 176.973 22.8588 176.306 21.6588C175.64 20.4255 174.74 19.4922 173.606 18.8588C172.473 18.1922 171.256 17.8588 169.956 17.8588C168.656 17.8588 167.456 18.1755 166.356 18.8088C165.256 19.4422 164.356 20.3755 163.656 21.6088C162.99 22.8088 162.656 24.2422 162.656 25.9088C162.656 27.5755 162.99 29.0422 163.656 30.3088C164.356 31.5422 165.256 32.4922 166.356 33.1588C167.49 33.8255 168.69 34.1588 169.956 34.1588C171.256 34.1588 172.473 33.8422 173.606 33.2088C174.74 32.5422 175.64 31.6088 176.306 30.4088C176.973 29.1755 177.306 27.7088 177.306 26.0088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M189.393 26.0088C189.393 23.1422 189.976 20.6422 191.143 18.5088C192.31 16.3422 193.926 14.6755 195.993 13.5088C198.06 12.3088 200.426 11.7088 203.093 11.7088C206.526 11.7088 209.36 12.5755 211.593 14.3088C213.86 16.0088 215.376 18.4088 216.143 21.5088H208.593C208.193 20.3088 207.51 19.3755 206.543 18.7088C205.61 18.0088 204.443 17.6588 203.043 17.6588C201.043 17.6588 199.46 18.3922 198.293 19.8588C197.126 21.2922 196.543 23.3422 196.543 26.0088C196.543 28.6422 197.126 30.6922 198.293 32.1588C199.46 33.5922 201.043 34.3088 203.043 34.3088C205.876 34.3088 207.726 33.0422 208.593 30.5088H216.143C215.376 33.5088 213.86 35.8922 211.593 37.6588C209.326 39.4255 206.493 40.3088 203.093 40.3088C200.426 40.3088 198.06 39.7255 195.993 38.5588C193.926 37.3588 192.31 35.6922 191.143 33.5588C189.976 31.3922 189.393 28.8755 189.393 26.0088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M237.671 39.8588L228.271 28.0588V39.8588H221.271V2.85882H228.271V23.9088L237.571 12.1588H246.671L234.471 26.0588L246.771 39.8588H237.671Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M259 25.9088C259 23.1088 259.55 20.6255 260.65 18.4588C261.783 16.2922 263.317 14.6255 265.25 13.4588C267.183 12.2922 269.333 11.7088 271.7 11.7088C273.5 11.7088 275.217 12.1088 276.85 12.9088C278.483 13.6755 279.783 14.7088 280.75 16.0088V2.85882H287.85V39.8588H280.75V35.7588C279.883 37.1255 278.667 38.2255 277.1 39.0588C275.533 39.8922 273.717 40.3088 271.65 40.3088C269.317 40.3088 267.183 39.7088 265.25 38.5088C263.317 37.3088 261.783 35.6255 260.65 33.4588C259.55 31.2588 259 28.7422 259 25.9088ZM280.8 26.0088C280.8 24.3088 280.467 22.8588 279.8 21.6588C279.133 20.4255 278.233 19.4922 277.1 18.8588C275.967 18.1922 274.75 17.8588 273.45 17.8588C272.15 17.8588 270.95 18.1755 269.85 18.8088C268.75 19.4422 267.85 20.3755 267.15 21.6088C266.483 22.8088 266.15 24.2422 266.15 25.9088C266.15 27.5755 266.483 29.0422 267.15 30.3088C267.85 31.5422 268.75 32.4922 269.85 33.1588C270.983 33.8255 272.183 34.1588 273.45 34.1588C274.75 34.1588 275.967 33.8422 277.1 33.2088C278.233 32.5422 279.133 31.6088 279.8 30.4088C280.467 29.1755 280.8 27.7088 280.8 26.0088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M320.437 25.4088C320.437 26.4088 320.37 27.3088 320.237 28.1088H299.987C300.153 30.1088 300.853 31.6755 302.087 32.8088C303.32 33.9422 304.837 34.5088 306.637 34.5088C309.237 34.5088 311.087 33.3922 312.187 31.1588H319.737C318.937 33.8255 317.403 36.0255 315.137 37.7588C312.87 39.4588 310.087 40.3088 306.787 40.3088C304.12 40.3088 301.72 39.7255 299.587 38.5588C297.487 37.3588 295.837 35.6755 294.637 33.5088C293.47 31.3422 292.887 28.8422 292.887 26.0088C292.887 23.1422 293.47 20.6255 294.637 18.4588C295.803 16.2922 297.437 14.6255 299.537 13.4588C301.637 12.2922 304.053 11.7088 306.787 11.7088C309.42 11.7088 311.77 12.2755 313.837 13.4088C315.937 14.5422 317.553 16.1588 318.687 18.2588C319.853 20.3255 320.437 22.7088 320.437 25.4088ZM313.187 23.4088C313.153 21.6088 312.503 20.1755 311.237 19.1088C309.97 18.0088 308.42 17.4588 306.587 17.4588C304.853 17.4588 303.387 17.9922 302.187 19.0588C301.02 20.0922 300.303 21.5422 300.037 23.4088H313.187Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M337.096 33.4088L344.096 12.1588H351.546L341.296 39.8588H332.796L322.596 12.1588H330.096L337.096 33.4088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M381.228 25.4088C381.228 26.4088 381.161 27.3088 381.028 28.1088H360.778C360.944 30.1088 361.644 31.6755 362.878 32.8088C364.111 33.9422 365.628 34.5088 367.428 34.5088C370.028 34.5088 371.878 33.3922 372.978 31.1588H380.528C379.728 33.8255 378.194 36.0255 375.928 37.7588C373.661 39.4588 370.878 40.3088 367.578 40.3088C364.911 40.3088 362.511 39.7255 360.378 38.5588C358.278 37.3588 356.628 35.6755 355.428 33.5088C354.261 31.3422 353.678 28.8422 353.678 26.0088C353.678 23.1422 354.261 20.6255 355.428 18.4588C356.594 16.2922 358.228 14.6255 360.328 13.4588C362.428 12.2922 364.844 11.7088 367.578 11.7088C370.211 11.7088 372.561 12.2755 374.628 13.4088C376.728 14.5422 378.344 16.1588 379.478 18.2588C380.644 20.3255 381.228 22.7088 381.228 25.4088ZM373.978 23.4088C373.944 21.6088 373.294 20.1755 372.028 19.1088C370.761 18.0088 369.211 17.4588 367.378 17.4588C365.644 17.4588 364.178 17.9922 362.978 19.0588C361.811 20.0922 361.094 21.5422 360.828 23.4088H373.978Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M393.337 2.85882V39.8588H386.337V2.85882H393.337Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M412.553 40.3088C409.886 40.3088 407.486 39.7255 405.353 38.5588C403.22 37.3588 401.536 35.6755 400.303 33.5088C399.103 31.3422 398.503 28.8422 398.503 26.0088C398.503 23.1755 399.12 20.6755 400.353 18.5088C401.62 16.3422 403.336 14.6755 405.503 13.5088C407.67 12.3088 410.086 11.7088 412.753 11.7088C415.42 11.7088 417.836 12.3088 420.003 13.5088C422.17 14.6755 423.87 16.3422 425.103 18.5088C426.37 20.6755 427.003 23.1755 427.003 26.0088C427.003 28.8422 426.353 31.3422 425.053 33.5088C423.786 35.6755 422.053 37.3588 419.853 38.5588C417.686 39.7255 415.253 40.3088 412.553 40.3088ZM412.553 34.2088C413.82 34.2088 415.003 33.9088 416.103 33.3088C417.236 32.6755 418.136 31.7422 418.803 30.5088C419.47 29.2755 419.803 27.7755 419.803 26.0088C419.803 23.3755 419.103 21.3588 417.703 19.9588C416.336 18.5255 414.653 17.8088 412.653 17.8088C410.653 17.8088 408.97 18.5255 407.603 19.9588C406.27 21.3588 405.603 23.3755 405.603 26.0088C405.603 28.6422 406.253 30.6755 407.553 32.1088C408.886 33.5088 410.553 34.2088 412.553 34.2088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M439.138 16.1588C440.038 14.8922 441.271 13.8422 442.838 13.0088C444.438 12.1422 446.255 11.7088 448.288 11.7088C450.655 11.7088 452.788 12.2922 454.688 13.4588C456.621 14.6255 458.138 16.2922 459.238 18.4588C460.371 20.5922 460.938 23.0755 460.938 25.9088C460.938 28.7422 460.371 31.2588 459.238 33.4588C458.138 35.6255 456.621 37.3088 454.688 38.5088C452.788 39.7088 450.655 40.3088 448.288 40.3088C446.255 40.3088 444.455 39.8922 442.888 39.0588C441.355 38.2255 440.105 37.1755 439.138 35.9088V53.0588H432.138V12.1588H439.138V16.1588ZM453.788 25.9088C453.788 24.2422 453.438 22.8088 452.738 21.6088C452.071 20.3755 451.171 19.4422 450.038 18.8088C448.938 18.1755 447.738 17.8588 446.438 17.8588C445.171 17.8588 443.971 18.1922 442.838 18.8588C441.738 19.4922 440.838 20.4255 440.138 21.6588C439.471 22.8922 439.138 24.3422 439.138 26.0088C439.138 27.6755 439.471 29.1255 440.138 30.3588C440.838 31.5922 441.738 32.5422 442.838 33.2088C443.971 33.8422 445.171 34.1588 446.438 34.1588C447.738 34.1588 448.938 33.8255 450.038 33.1588C451.171 32.4922 452.071 31.5422 452.738 30.3088C453.438 29.0755 453.788 27.6088 453.788 25.9088Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M491.775 25.4088C491.775 26.4088 491.708 27.3088 491.575 28.1088H471.325C471.491 30.1088 472.191 31.6755 473.425 32.8088C474.658 33.9422 476.175 34.5088 477.975 34.5088C480.575 34.5088 482.425 33.3922 483.525 31.1588H491.075C490.275 33.8255 488.741 36.0255 486.475 37.7588C484.208 39.4588 481.425 40.3088 478.125 40.3088C475.458 40.3088 473.058 39.7255 470.925 38.5588C468.825 37.3588 467.175 35.6755 465.975 33.5088C464.808 31.3422 464.225 28.8422 464.225 26.0088C464.225 23.1422 464.808 20.6255 465.975 18.4588C467.141 16.2922 468.775 14.6255 470.875 13.4588C472.975 12.2922 475.391 11.7088 478.125 11.7088C480.758 11.7088 483.108 12.2755 485.175 13.4088C487.275 14.5422 488.891 16.1588 490.025 18.2588C491.191 20.3255 491.775 22.7088 491.775 25.4088ZM484.525 23.4088C484.491 21.6088 483.841 20.1755 482.575 19.1088C481.308 18.0088 479.758 17.4588 477.925 17.4588C476.191 17.4588 474.725 17.9922 473.525 19.0588C472.358 20.0922 471.641 21.5422 471.375 23.4088H484.525Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M503.884 16.4588C504.784 14.9922 505.951 13.8422 507.384 13.0088C508.851 12.1755 510.517 11.7588 512.384 11.7588V19.1088H510.534C508.334 19.1088 506.667 19.6255 505.534 20.6588C504.434 21.6922 503.884 23.4922 503.884 26.0588V39.8588H496.884V12.1588H503.884V16.4588Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M520.199 40.2088C518.932 40.2088 517.882 39.8255 517.049 39.0588C516.249 38.2588 515.849 37.2755 515.849 36.1088C515.849 34.9422 516.249 33.9755 517.049 33.2088C517.882 32.4088 518.932 32.0088 520.199 32.0088C521.432 32.0088 522.449 32.4088 523.249 33.2088C524.049 33.9755 524.449 34.9422 524.449 36.1088C524.449 37.2755 524.049 38.2588 523.249 39.0588C522.449 39.8255 521.432 40.2088 520.199 40.2088Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default DeveloperAnimationEn;
