import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import './Info.scss';

const skills = ["JavaScript", "React", "Redux", "NodeJs", "ExpressJs", "Golang", "React-Native", "MongoDB", "PostgrSQL", "HTML", "CSS & Sass", "Tailwind", "Storybook", "Jest", "HTTP & REST", "Git", "AdobeXd", "Ngrok" ]

const SkillsInfo = () => (
  <div className='SkillsInfo'>
    <div className='SkillsInfo_column'>
      {skills.slice(0, 6).map(skill => (
          <div className='SkillsInfo_row'>
            <FontAwesomeIcon className='SkillsInfo_icon' icon={faCaretRight} />
            <p>{skill}</p>
          </div>
      ))}
    </div>
    <div className='SkillsInfo_column'>
      {skills.slice(6, 12).map(skill => (
          <div className='SkillsInfo_row'>
            <FontAwesomeIcon className='SkillsInfo_icon' icon={faCaretRight} />
            <p>{skill}</p>
          </div>
      ))}
    </div>
    <div className='SkillsInfo_column'>
      {skills.slice(12, skills.length).map(skill => (
          <div className='SkillsInfo_row'>
            <FontAwesomeIcon className='SkillsInfo_icon' icon={faCaretRight} />
            <p>{skill}</p>
          </div>
      ))}
    </div>
  </div>
);

export default SkillsInfo;
