import './NameStrokeEn.scss';

const NameStrokeEn = () => (
  <div className='NameStrokeEn'>
    <svg
      id='nameEn'
      viewBox='0 0 477 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.65 5.09999C17.3167 5.09999 20.5333 5.81665 23.3 7.24999C26.1 8.68332 28.25 10.7333 29.75 13.4C31.2833 16.0333 32.05 19.1 32.05 22.6C32.05 26.1 31.2833 29.1667 29.75 31.8C28.25 34.4 26.1 36.4167 23.3 37.85C20.5333 39.2833 17.3167 40 13.65 40H1.45001V5.09999H13.65ZM13.4 34.05C17.0667 34.05 19.9 33.05 21.9 31.05C23.9 29.05 24.9 26.2333 24.9 22.6C24.9 18.9667 23.9 16.1333 21.9 14.1C19.9 12.0333 17.0667 11 13.4 11H8.45001V34.05H13.4Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M40.8399 8.99999C39.6065 8.99999 38.5732 8.61666 37.7399 7.84999C36.9399 7.04999 36.5399 6.06665 36.5399 4.89999C36.5399 3.73332 36.9399 2.76665 37.7399 1.99999C38.5732 1.19999 39.6065 0.799988 40.8399 0.799988C42.0732 0.799988 43.0899 1.19999 43.8899 1.99999C44.7232 2.76665 45.1399 3.73332 45.1399 4.89999C45.1399 6.06665 44.7232 7.04999 43.8899 7.84999C43.0899 8.61666 42.0732 8.99999 40.8399 8.99999ZM44.2899 12.3V40H37.2899V12.3H44.2899Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M58.2059 2.99999V40H51.2059V2.99999H58.2059Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M91.6219 12.3L74.4719 53.1H67.0219L73.0219 39.3L61.9219 12.3H69.7719L76.9219 31.65L84.1719 12.3H91.6219Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M93.5953 26.05C93.5953 23.25 94.1453 20.7667 95.2453 18.6C96.3787 16.4333 97.8953 14.7667 99.7953 13.6C101.729 12.4333 103.879 11.85 106.245 11.85C108.312 11.85 110.112 12.2667 111.645 13.1C113.212 13.9333 114.462 14.9833 115.395 16.25V12.3H122.445V40H115.395V35.95C114.495 37.25 113.245 38.3333 111.645 39.2C110.079 40.0333 108.262 40.45 106.195 40.45C103.862 40.45 101.729 39.85 99.7953 38.65C97.8953 37.45 96.3787 35.7667 95.2453 33.6C94.1453 31.4 93.5953 28.8833 93.5953 26.05ZM115.395 26.15C115.395 24.45 115.062 23 114.395 21.8C113.729 20.5667 112.829 19.6333 111.695 19C110.562 18.3333 109.345 18 108.045 18C106.745 18 105.545 18.3167 104.445 18.95C103.345 19.5833 102.445 20.5167 101.745 21.75C101.079 22.95 100.745 24.3833 100.745 26.05C100.745 27.7167 101.079 29.1833 101.745 30.45C102.445 31.6833 103.345 32.6333 104.445 33.3C105.579 33.9667 106.779 34.3 108.045 34.3C109.345 34.3 110.562 33.9833 111.695 33.35C112.829 32.6833 113.729 31.75 114.395 30.55C115.062 29.3167 115.395 27.85 115.395 26.15Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M144.632 11.9C147.932 11.9 150.599 12.95 152.632 15.05C154.665 17.1167 155.682 20.0167 155.682 23.75V40H148.682V24.7C148.682 22.5 148.132 20.8167 147.032 19.65C145.932 18.45 144.432 17.85 142.532 17.85C140.599 17.85 139.065 18.45 137.932 19.65C136.832 20.8167 136.282 22.5 136.282 24.7V40H129.282V12.3H136.282V15.75C137.215 14.55 138.399 13.6167 139.832 12.95C141.299 12.25 142.899 11.9 144.632 11.9Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M160.539 26.05C160.539 23.25 161.089 20.7667 162.189 18.6C163.322 16.4333 164.839 14.7667 166.739 13.6C168.672 12.4333 170.822 11.85 173.189 11.85C175.255 11.85 177.055 12.2667 178.589 13.1C180.155 13.9333 181.405 14.9833 182.339 16.25V12.3H189.389V40H182.339V35.95C181.439 37.25 180.189 38.3333 178.589 39.2C177.022 40.0333 175.205 40.45 173.139 40.45C170.805 40.45 168.672 39.85 166.739 38.65C164.839 37.45 163.322 35.7667 162.189 33.6C161.089 31.4 160.539 28.8833 160.539 26.05ZM182.339 26.15C182.339 24.45 182.005 23 181.339 21.8C180.672 20.5667 179.772 19.6333 178.639 19C177.505 18.3333 176.289 18 174.989 18C173.689 18 172.489 18.3167 171.389 18.95C170.289 19.5833 169.389 20.5167 168.689 21.75C168.022 22.95 167.689 24.3833 167.689 26.05C167.689 27.7167 168.022 29.1833 168.689 30.45C169.389 31.6833 170.289 32.6333 171.389 33.3C172.522 33.9667 173.722 34.3 174.989 34.3C176.289 34.3 177.505 33.9833 178.639 33.35C179.772 32.6833 180.672 31.75 181.339 30.55C182.005 29.3167 182.339 27.85 182.339 26.15Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M220.189 40.35C217.756 40.35 215.556 39.9333 213.589 39.1C211.656 38.2667 210.123 37.0667 208.989 35.5C207.856 33.9333 207.273 32.0833 207.239 29.95H214.739C214.839 31.3833 215.339 32.5167 216.239 33.35C217.173 34.1833 218.439 34.6 220.039 34.6C221.673 34.6 222.956 34.2167 223.889 33.45C224.823 32.65 225.289 31.6167 225.289 30.35C225.289 29.3167 224.973 28.4667 224.339 27.8C223.706 27.1333 222.906 26.6167 221.939 26.25C221.006 25.85 219.706 25.4167 218.039 24.95C215.773 24.2833 213.923 23.6333 212.489 23C211.089 22.3333 209.873 21.35 208.839 20.05C207.839 18.7167 207.339 16.95 207.339 14.75C207.339 12.6833 207.856 10.8833 208.889 9.34999C209.923 7.81665 211.373 6.64999 213.239 5.84999C215.106 5.01666 217.239 4.59999 219.639 4.59999C223.239 4.59999 226.156 5.48332 228.389 7.24999C230.656 8.98332 231.906 11.4167 232.139 14.55H224.439C224.373 13.35 223.856 12.3667 222.889 11.6C221.956 10.8 220.706 10.4 219.139 10.4C217.773 10.4 216.673 10.75 215.839 11.45C215.039 12.15 214.639 13.1667 214.639 14.5C214.639 15.4333 214.939 16.2167 215.539 16.85C216.173 17.45 216.939 17.95 217.839 18.35C218.773 18.7167 220.073 19.15 221.739 19.65C224.006 20.3167 225.856 20.9833 227.289 21.65C228.723 22.3167 229.956 23.3167 230.989 24.65C232.023 25.9833 232.539 27.7333 232.539 29.9C232.539 31.7667 232.056 33.5 231.089 35.1C230.123 36.7 228.706 37.9833 226.839 38.95C224.973 39.8833 222.756 40.35 220.189 40.35Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M246.758 18.05V31.45C246.758 32.3833 246.975 33.0667 247.408 33.5C247.875 33.9 248.642 34.1 249.708 34.1H252.958V40H248.558C242.658 40 239.708 37.1333 239.708 31.4V18.05H236.408V12.3H239.708V5.44999H246.758V12.3H252.958V18.05H246.758Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M283.743 25.55C283.743 26.55 283.676 27.45 283.543 28.25H263.293C263.46 30.25 264.16 31.8167 265.393 32.95C266.626 34.0833 268.143 34.65 269.943 34.65C272.543 34.65 274.393 33.5333 275.493 31.3H283.043C282.243 33.9667 280.71 36.1667 278.443 37.9C276.176 39.6 273.393 40.45 270.093 40.45C267.426 40.45 265.026 39.8667 262.893 38.7C260.793 37.5 259.143 35.8167 257.943 33.65C256.776 31.4833 256.193 28.9833 256.193 26.15C256.193 23.2833 256.776 20.7667 257.943 18.6C259.11 16.4333 260.743 14.7667 262.843 13.6C264.943 12.4333 267.36 11.85 270.093 11.85C272.726 11.85 275.076 12.4167 277.143 13.55C279.243 14.6833 280.86 16.3 281.993 18.4C283.16 20.4667 283.743 22.85 283.743 25.55ZM276.493 23.55C276.46 21.75 275.81 20.3167 274.543 19.25C273.276 18.15 271.726 17.6 269.893 17.6C268.16 17.6 266.693 18.1333 265.493 19.2C264.326 20.2333 263.61 21.6833 263.343 23.55H276.493Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M301.552 18.05H296.702V40H289.602V18.05H286.452V12.3H289.602V10.9C289.602 7.49999 290.569 4.99999 292.502 3.39999C294.436 1.79999 297.352 1.04999 301.252 1.14999V7.04999C299.552 7.01665 298.369 7.29999 297.702 7.89999C297.036 8.49999 296.702 9.58332 296.702 11.15V12.3H301.552V18.05Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M304.289 26.05C304.289 23.25 304.839 20.7667 305.939 18.6C307.072 16.4333 308.589 14.7667 310.489 13.6C312.422 12.4333 314.572 11.85 316.939 11.85C319.005 11.85 320.805 12.2667 322.339 13.1C323.905 13.9333 325.155 14.9833 326.089 16.25V12.3H333.139V40H326.089V35.95C325.189 37.25 323.939 38.3333 322.339 39.2C320.772 40.0333 318.955 40.45 316.889 40.45C314.555 40.45 312.422 39.85 310.489 38.65C308.589 37.45 307.072 35.7667 305.939 33.6C304.839 31.4 304.289 28.8833 304.289 26.05ZM326.089 26.15C326.089 24.45 325.755 23 325.089 21.8C324.422 20.5667 323.522 19.6333 322.389 19C321.255 18.3333 320.039 18 318.739 18C317.439 18 316.239 18.3167 315.139 18.95C314.039 19.5833 313.139 20.5167 312.439 21.75C311.772 22.95 311.439 24.3833 311.439 26.05C311.439 27.7167 311.772 29.1833 312.439 30.45C313.139 31.6833 314.039 32.6333 315.139 33.3C316.272 33.9667 317.472 34.3 318.739 34.3C320.039 34.3 321.255 33.9833 322.389 33.35C323.522 32.6833 324.422 31.75 325.089 30.55C325.755 29.3167 326.089 27.85 326.089 26.15Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M355.325 11.9C358.625 11.9 361.292 12.95 363.325 15.05C365.359 17.1167 366.375 20.0167 366.375 23.75V40H359.375V24.7C359.375 22.5 358.825 20.8167 357.725 19.65C356.625 18.45 355.125 17.85 353.225 17.85C351.292 17.85 349.759 18.45 348.625 19.65C347.525 20.8167 346.975 22.5 346.975 24.7V40H339.975V12.3H346.975V15.75C347.909 14.55 349.092 13.6167 350.525 12.95C351.992 12.25 353.592 11.9 355.325 11.9Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M385.332 40.45C382.665 40.45 380.265 39.8667 378.132 38.7C375.999 37.5 374.315 35.8167 373.082 33.65C371.882 31.4833 371.282 28.9833 371.282 26.15C371.282 23.3167 371.899 20.8167 373.132 18.65C374.399 16.4833 376.115 14.8167 378.282 13.65C380.449 12.45 382.865 11.85 385.532 11.85C388.199 11.85 390.615 12.45 392.782 13.65C394.949 14.8167 396.649 16.4833 397.882 18.65C399.149 20.8167 399.782 23.3167 399.782 26.15C399.782 28.9833 399.132 31.4833 397.832 33.65C396.565 35.8167 394.832 37.5 392.632 38.7C390.465 39.8667 388.032 40.45 385.332 40.45ZM385.332 34.35C386.599 34.35 387.782 34.05 388.882 33.45C390.015 32.8167 390.915 31.8833 391.582 30.65C392.249 29.4167 392.582 27.9167 392.582 26.15C392.582 23.5167 391.882 21.5 390.482 20.1C389.115 18.6667 387.432 17.95 385.432 17.95C383.432 17.95 381.749 18.6667 380.382 20.1C379.049 21.5 378.382 23.5167 378.382 26.15C378.382 28.7833 379.032 30.8167 380.332 32.25C381.665 33.65 383.332 34.35 385.332 34.35Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M416.467 33.55L423.467 12.3H430.917L420.667 40H412.167L401.967 12.3H409.467L416.467 33.55Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M433.048 26.05C433.048 23.25 433.598 20.7667 434.698 18.6C435.832 16.4333 437.348 14.7667 439.248 13.6C441.182 12.4333 443.332 11.85 445.698 11.85C447.765 11.85 449.565 12.2667 451.098 13.1C452.665 13.9333 453.915 14.9833 454.848 16.25V12.3H461.898V40H454.848V35.95C453.948 37.25 452.698 38.3333 451.098 39.2C449.532 40.0333 447.715 40.45 445.648 40.45C443.315 40.45 441.182 39.85 439.248 38.65C437.348 37.45 435.832 35.7667 434.698 33.6C433.598 31.4 433.048 28.8833 433.048 26.05ZM454.848 26.15C454.848 24.45 454.515 23 453.848 21.8C453.182 20.5667 452.282 19.6333 451.148 19C450.015 18.3333 448.798 18 447.498 18C446.198 18 444.998 18.3167 443.898 18.95C442.798 19.5833 441.898 20.5167 441.198 21.75C440.532 22.95 440.198 24.3833 440.198 26.05C440.198 27.7167 440.532 29.1833 441.198 30.45C441.898 31.6833 442.798 32.6333 443.898 33.3C445.032 33.9667 446.232 34.3 447.498 34.3C448.798 34.3 450.015 33.9833 451.148 33.35C452.282 32.6833 453.182 31.75 453.848 30.55C454.515 29.3167 454.848 27.85 454.848 26.15Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M471.835 40.35C470.568 40.35 469.518 39.9667 468.685 39.2C467.885 38.4 467.485 37.4167 467.485 36.25C467.485 35.0833 467.885 34.1167 468.685 33.35C469.518 32.55 470.568 32.15 471.835 32.15C473.068 32.15 474.085 32.55 474.885 33.35C475.685 34.1167 476.085 35.0833 476.085 36.25C476.085 37.4167 475.685 38.4 474.885 39.2C474.085 39.9667 473.068 40.35 471.835 40.35Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default NameStrokeEn;
