import './DeveloperAnimationEs.scss';

const DeveloperAnimationEs = () => (
  <div className='DeveloperAnimationEs'>
    <svg
      id='developerEs'
      width='693'
      height='45'
      viewBox='0 0 693 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 28.7279C1 25.7466 1.55467 23.1292 2.664 20.8759C3.808 18.6226 5.35067 16.8892 7.292 15.6759C9.23333 14.4626 11.4 13.8559 13.792 13.8559C15.6987 13.8559 17.432 14.2546 18.992 15.0519C20.5867 15.8492 21.8347 16.9239 22.736 18.2759V4.80791H31.628V43.2879H22.736V39.1279C21.904 40.5146 20.708 41.6239 19.148 42.4559C17.6227 43.2879 15.8373 43.7039 13.792 43.7039C11.4 43.7039 9.23333 43.0972 7.292 41.8839C5.35067 40.6359 3.808 38.8852 2.664 36.6319C1.55467 34.3439 1 31.7092 1 28.7279ZM22.736 28.7799C22.736 26.5612 22.112 24.8106 20.864 23.5279C19.6507 22.2452 18.16 21.6039 16.392 21.6039C14.624 21.6039 13.116 22.2452 11.868 23.5279C10.6547 24.7759 10.048 26.5092 10.048 28.7279C10.048 30.9466 10.6547 32.7146 11.868 34.0319C13.116 35.3146 14.624 35.9559 16.392 35.9559C18.16 35.9559 19.6507 35.3146 20.864 34.0319C22.112 32.7492 22.736 30.9986 22.736 28.7799Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M65.413 28.3119C65.413 29.1439 65.361 30.0106 65.257 30.9119H45.133C45.2716 32.7146 45.8436 34.1012 46.849 35.0719C47.889 36.0079 49.1543 36.4759 50.645 36.4759C52.8636 36.4759 54.4063 35.5399 55.273 33.6679H64.737C64.2516 35.5746 63.3676 37.2906 62.085 38.8159C60.837 40.3412 59.2596 41.5372 57.353 42.4039C55.4463 43.2706 53.3143 43.7039 50.957 43.7039C48.1143 43.7039 45.5836 43.0972 43.365 41.8839C41.1463 40.6706 39.413 38.9372 38.165 36.6839C36.917 34.4306 36.293 31.7959 36.293 28.7799C36.293 25.7639 36.8996 23.1292 38.113 20.8759C39.361 18.6226 41.0943 16.8892 43.313 15.6759C45.5316 14.4626 48.0796 13.8559 50.957 13.8559C53.765 13.8559 56.261 14.4452 58.445 15.6239C60.629 16.8026 62.3276 18.4839 63.541 20.6679C64.789 22.8519 65.413 25.3999 65.413 28.3119ZM56.313 25.9719C56.313 24.4466 55.793 23.2332 54.753 22.3319C53.713 21.4306 52.413 20.9799 50.853 20.9799C49.3623 20.9799 48.097 21.4132 47.057 22.2799C46.0516 23.1466 45.4276 24.3772 45.185 25.9719H56.313Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M81.9079 43.7039C79.3773 43.7039 77.1239 43.2706 75.1479 42.4039C73.1719 41.5372 71.6119 40.3586 70.4679 38.8679C69.3239 37.3426 68.6826 35.6439 68.5439 33.7719H77.3319C77.4359 34.7772 77.9039 35.5919 78.7359 36.2159C79.5679 36.8399 80.5906 37.1519 81.8039 37.1519C82.9133 37.1519 83.7626 36.9439 84.3519 36.5279C84.9759 36.0772 85.2879 35.5052 85.2879 34.8119C85.2879 33.9799 84.8546 33.3732 83.9879 32.9919C83.1213 32.5759 81.7173 32.1252 79.7759 31.6399C77.6959 31.1546 75.9626 30.6519 74.5759 30.1319C73.1893 29.5772 71.9933 28.7279 70.9879 27.5839C69.9826 26.4052 69.4799 24.8279 69.4799 22.8519C69.4799 21.1879 69.9306 19.6799 70.8319 18.3279C71.7679 16.9412 73.1199 15.8492 74.8879 15.0519C76.6906 14.2546 78.8226 13.8559 81.2839 13.8559C84.9239 13.8559 87.7839 14.7572 89.8639 16.5599C91.9786 18.3626 93.1919 20.7546 93.5039 23.7359H85.2879C85.1493 22.7306 84.6986 21.9332 83.9359 21.3439C83.2079 20.7546 82.2373 20.4599 81.0239 20.4599C79.9839 20.4599 79.1866 20.6679 78.6319 21.0839C78.0773 21.4652 77.7999 22.0026 77.7999 22.6959C77.7999 23.5279 78.2333 24.1519 79.0999 24.5679C80.0013 24.9839 81.3879 25.3999 83.2599 25.8159C85.4093 26.3706 87.1599 26.9252 88.5119 27.4799C89.8639 27.9999 91.0426 28.8666 92.0479 30.0799C93.0879 31.2586 93.6253 32.8532 93.6599 34.8639C93.6599 36.5626 93.1746 38.0879 92.2039 39.4399C91.2679 40.7572 89.8986 41.7972 88.0959 42.5599C86.3279 43.3226 84.2653 43.7039 81.9079 43.7039Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M97.332 28.7279C97.332 25.7466 97.8867 23.1292 98.996 20.8759C100.14 18.6226 101.683 16.8892 103.624 15.6759C105.565 14.4626 107.732 13.8559 110.124 13.8559C112.169 13.8559 113.955 14.2719 115.48 15.1039C117.04 15.9359 118.236 17.0279 119.068 18.3799V14.2719H127.96V43.2879H119.068V39.1799C118.201 40.5319 116.988 41.6239 115.428 42.4559C113.903 43.2879 112.117 43.7039 110.072 43.7039C107.715 43.7039 105.565 43.0972 103.624 41.8839C101.683 40.6359 100.14 38.8852 98.996 36.6319C97.8867 34.3439 97.332 31.7092 97.332 28.7279ZM119.068 28.7799C119.068 26.5612 118.444 24.8106 117.196 23.5279C115.983 22.2452 114.492 21.6039 112.724 21.6039C110.956 21.6039 109.448 22.2452 108.2 23.5279C106.987 24.7759 106.38 26.5092 106.38 28.7279C106.38 30.9466 106.987 32.7146 108.2 34.0319C109.448 35.3146 110.956 35.9559 112.724 35.9559C114.492 35.9559 115.983 35.3146 117.196 34.0319C118.444 32.7492 119.068 30.9986 119.068 28.7799Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M143.285 19.1079C144.325 17.5132 145.625 16.2652 147.185 15.3639C148.745 14.4279 150.478 13.9599 152.385 13.9599V23.3719H149.941C147.722 23.3719 146.058 23.8572 144.949 24.8279C143.84 25.7639 143.285 27.4279 143.285 29.8199V43.2879H134.393V14.2719H143.285V19.1079Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M165.527 19.1079C166.567 17.5132 167.867 16.2652 169.427 15.3639C170.987 14.4279 172.721 13.9599 174.627 13.9599V23.3719H172.183C169.965 23.3719 168.301 23.8572 167.191 24.8279C166.082 25.7639 165.527 27.4279 165.527 29.8199V43.2879H156.635V14.2719H165.527V19.1079Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M192.085 43.7039C189.243 43.7039 186.677 43.0972 184.389 41.8839C182.136 40.6706 180.351 38.9372 179.033 36.6839C177.751 34.4306 177.109 31.7959 177.109 28.7799C177.109 25.7986 177.768 23.1812 179.085 20.9279C180.403 18.6399 182.205 16.8892 184.493 15.6759C186.781 14.4626 189.347 13.8559 192.189 13.8559C195.032 13.8559 197.597 14.4626 199.885 15.6759C202.173 16.8892 203.976 18.6399 205.293 20.9279C206.611 23.1812 207.269 25.7986 207.269 28.7799C207.269 31.7612 206.593 34.3959 205.241 36.6839C203.924 38.9372 202.104 40.6706 199.781 41.8839C197.493 43.0972 194.928 43.7039 192.085 43.7039ZM192.085 36.0079C193.784 36.0079 195.223 35.3839 196.401 34.1359C197.615 32.8879 198.221 31.1026 198.221 28.7799C198.221 26.4572 197.632 24.6719 196.453 23.4239C195.309 22.1759 193.888 21.5519 192.189 21.5519C190.456 21.5519 189.017 22.1759 187.873 23.4239C186.729 24.6372 186.157 26.4226 186.157 28.7799C186.157 31.1026 186.712 32.8879 187.821 34.1359C188.965 35.3839 190.387 36.0079 192.085 36.0079Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M220.879 4.80791V43.2879H211.987V4.80791H220.879Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M236.215 4.80791V43.2879H227.323V4.80791H236.215Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M240.891 28.7279C240.891 25.7466 241.445 23.1292 242.555 20.8759C243.699 18.6226 245.241 16.8892 247.183 15.6759C249.124 14.4626 251.291 13.8559 253.683 13.8559C255.728 13.8559 257.513 14.2719 259.039 15.1039C260.599 15.9359 261.795 17.0279 262.627 18.3799V14.2719H271.519V43.2879H262.627V39.1799C261.76 40.5319 260.547 41.6239 258.987 42.4559C257.461 43.2879 255.676 43.7039 253.631 43.7039C251.273 43.7039 249.124 43.0972 247.183 41.8839C245.241 40.6359 243.699 38.8852 242.555 36.6319C241.445 34.3439 240.891 31.7092 240.891 28.7279ZM262.627 28.7799C262.627 26.5612 262.003 24.8106 260.755 23.5279C259.541 22.2452 258.051 21.6039 256.283 21.6039C254.515 21.6039 253.007 22.2452 251.759 23.5279C250.545 24.7759 249.939 26.5092 249.939 28.7279C249.939 30.9466 250.545 32.7146 251.759 34.0319C253.007 35.3146 254.515 35.9559 256.283 35.9559C258.051 35.9559 259.541 35.3146 260.755 34.0319C262.003 32.7492 262.627 30.9986 262.627 28.7799Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M276.184 28.7279C276.184 25.7466 276.738 23.1292 277.848 20.8759C278.992 18.6226 280.534 16.8892 282.476 15.6759C284.417 14.4626 286.584 13.8559 288.976 13.8559C290.882 13.8559 292.616 14.2546 294.176 15.0519C295.77 15.8492 297.018 16.9239 297.92 18.2759V4.80791H306.812V43.2879H297.92V39.1279C297.088 40.5146 295.892 41.6239 294.332 42.4559C292.806 43.2879 291.021 43.7039 288.976 43.7039C286.584 43.7039 284.417 43.0972 282.476 41.8839C280.534 40.6359 278.992 38.8852 277.848 36.6319C276.738 34.3439 276.184 31.7092 276.184 28.7279ZM297.92 28.7799C297.92 26.5612 297.296 24.8106 296.048 23.5279C294.834 22.2452 293.344 21.6039 291.576 21.6039C289.808 21.6039 288.3 22.2452 287.052 23.5279C285.838 24.7759 285.232 26.5092 285.232 28.7279C285.232 30.9466 285.838 32.7146 287.052 34.0319C288.3 35.3146 289.808 35.9559 291.576 35.9559C293.344 35.9559 294.834 35.3146 296.048 34.0319C297.296 32.7492 297.92 30.9986 297.92 28.7799Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M326.453 43.7039C323.61 43.7039 321.045 43.0972 318.757 41.8839C316.503 40.6706 314.718 38.9372 313.401 36.6839C312.118 34.4306 311.477 31.7959 311.477 28.7799C311.477 25.7986 312.135 23.1812 313.453 20.9279C314.77 18.6399 316.573 16.8892 318.861 15.6759C321.149 14.4626 323.714 13.8559 326.557 13.8559C329.399 13.8559 331.965 14.4626 334.253 15.6759C336.541 16.8892 338.343 18.6399 339.661 20.9279C340.978 23.1812 341.637 25.7986 341.637 28.7799C341.637 31.7612 340.961 34.3959 339.609 36.6839C338.291 38.9372 336.471 40.6706 334.149 41.8839C331.861 43.0972 329.295 43.7039 326.453 43.7039ZM326.453 36.0079C328.151 36.0079 329.59 35.3839 330.769 34.1359C331.982 32.8879 332.589 31.1026 332.589 28.7799C332.589 26.4572 331.999 24.6719 330.821 23.4239C329.677 22.1759 328.255 21.5519 326.557 21.5519C324.823 21.5519 323.385 22.1759 322.241 23.4239C321.097 24.6372 320.525 26.4226 320.525 28.7799C320.525 31.1026 321.079 32.8879 322.189 34.1359C323.333 35.3839 324.754 36.0079 326.453 36.0079Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M355.246 19.1079C356.286 17.5132 357.586 16.2652 359.146 15.3639C360.706 14.4279 362.439 13.9599 364.346 13.9599V23.3719H361.902C359.683 23.3719 358.019 23.8572 356.91 24.8279C355.801 25.7639 355.246 27.4279 355.246 29.8199V43.2879H346.354V14.2719H355.246V19.1079Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M366.828 28.7279C366.828 25.7466 367.383 23.1292 368.492 20.8759C369.636 18.6226 371.179 16.8892 373.12 15.6759C375.061 14.4626 377.228 13.8559 379.62 13.8559C381.665 13.8559 383.451 14.2719 384.976 15.1039C386.536 15.9359 387.732 17.0279 388.564 18.3799V14.2719H397.456V43.2879H388.564V39.1799C387.697 40.5319 386.484 41.6239 384.924 42.4559C383.399 43.2879 381.613 43.7039 379.568 43.7039C377.211 43.7039 375.061 43.0972 373.12 41.8839C371.179 40.6359 369.636 38.8852 368.492 36.6319C367.383 34.3439 366.828 31.7092 366.828 28.7279ZM388.564 28.7799C388.564 26.5612 387.94 24.8106 386.692 23.5279C385.479 22.2452 383.988 21.6039 382.22 21.6039C380.452 21.6039 378.944 22.2452 377.696 23.5279C376.483 24.7759 375.876 26.5092 375.876 28.7279C375.876 30.9466 376.483 32.7146 377.696 34.0319C378.944 35.3146 380.452 35.9559 382.22 35.9559C383.988 35.9559 385.479 35.3146 386.692 34.0319C387.94 32.7492 388.564 30.9986 388.564 28.7799Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M432.9 20.512H428.116V42.144H419.224V20.512H416V13.128H419.224V12.296C419.224 8.72534 420.247 6.02134 422.292 4.18401C424.337 2.31201 427.336 1.37601 431.288 1.37601C431.947 1.37601 432.432 1.39334 432.744 1.42801V8.96801C431.045 8.86401 429.849 9.10667 429.156 9.69601C428.463 10.2853 428.116 11.3427 428.116 12.868V13.128H432.9V20.512Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M465.73 13.128V42.144H456.838V38.192C455.937 39.4747 454.706 40.5147 453.146 41.312C451.621 42.0747 449.922 42.456 448.05 42.456C445.832 42.456 443.873 41.9707 442.174 41C440.476 39.9947 439.158 38.556 438.222 36.684C437.286 34.812 436.818 32.6107 436.818 30.08V13.128H445.658V28.884C445.658 30.8253 446.161 32.3333 447.166 33.408C448.172 34.4827 449.524 35.02 451.222 35.02C452.956 35.02 454.325 34.4827 455.33 33.408C456.336 32.3333 456.838 30.8253 456.838 28.884V13.128H465.73Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M481.009 3.66401V42.144H472.117V3.66401H481.009Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M496.345 3.66401V42.144H487.453V3.66401H496.345Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M525.149 20.2V27.584H502.269V20.2H525.149Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M544.757 42.56C542.227 42.56 539.973 42.1267 537.997 41.26C536.021 40.3933 534.461 39.2147 533.317 37.724C532.173 36.1987 531.532 34.5 531.393 32.628H540.181C540.285 33.6333 540.753 34.448 541.585 35.072C542.417 35.696 543.44 36.008 544.653 36.008C545.763 36.008 546.612 35.8 547.201 35.384C547.825 34.9333 548.137 34.3613 548.137 33.668C548.137 32.836 547.704 32.2293 546.837 31.848C545.971 31.432 544.567 30.9813 542.625 30.496C540.545 30.0107 538.812 29.508 537.425 28.988C536.039 28.4333 534.843 27.584 533.837 26.44C532.832 25.2613 532.329 23.684 532.329 21.708C532.329 20.044 532.78 18.536 533.681 17.184C534.617 15.7973 535.969 14.7053 537.737 13.908C539.54 13.1107 541.672 12.712 544.133 12.712C547.773 12.712 550.633 13.6133 552.713 15.416C554.828 17.2187 556.041 19.6107 556.353 22.592H548.137C547.999 21.5867 547.548 20.7893 546.785 20.2C546.057 19.6107 545.087 19.316 543.873 19.316C542.833 19.316 542.036 19.524 541.481 19.94C540.927 20.3213 540.649 20.8587 540.649 21.552C540.649 22.384 541.083 23.008 541.949 23.424C542.851 23.84 544.237 24.256 546.109 24.672C548.259 25.2267 550.009 25.7813 551.361 26.336C552.713 26.856 553.892 27.7227 554.897 28.936C555.937 30.1147 556.475 31.7093 556.509 33.72C556.509 35.4187 556.024 36.944 555.053 38.296C554.117 39.6133 552.748 40.6533 550.945 41.416C549.177 42.1787 547.115 42.56 544.757 42.56Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M578.121 34.604V42.144H573.597C570.373 42.144 567.86 41.364 566.057 39.804C564.255 38.2093 563.353 35.6267 563.353 32.056V20.512H559.817V13.128H563.353V6.05601H572.245V13.128H578.069V20.512H572.245V32.16C572.245 33.0267 572.453 33.6507 572.869 34.032C573.285 34.4133 573.979 34.604 574.949 34.604H578.121Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M581.306 27.584C581.306 24.6027 581.861 21.9853 582.97 19.732C584.114 17.4787 585.657 15.7453 587.598 14.532C589.54 13.3187 591.706 12.712 594.098 12.712C596.144 12.712 597.929 13.128 599.454 13.96C601.014 14.792 602.21 15.884 603.042 17.236V13.128H611.934V42.144H603.042V38.036C602.176 39.388 600.962 40.48 599.402 41.312C597.877 42.144 596.092 42.56 594.046 42.56C591.689 42.56 589.54 41.9533 587.598 40.74C585.657 39.492 584.114 37.7413 582.97 35.488C581.861 33.2 581.306 30.5653 581.306 27.584ZM603.042 27.636C603.042 25.4173 602.418 23.6667 601.17 22.384C599.957 21.1013 598.466 20.46 596.698 20.46C594.93 20.46 593.422 21.1013 592.174 22.384C590.961 23.632 590.354 25.3653 590.354 27.584C590.354 29.8027 590.961 31.5707 592.174 32.888C593.422 34.1707 594.93 34.812 596.698 34.812C598.466 34.812 599.957 34.1707 601.17 32.888C602.418 31.6053 603.042 29.8547 603.042 27.636Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M616.599 27.636C616.599 24.62 617.206 21.9853 618.419 19.732C619.667 17.4787 621.383 15.7453 623.567 14.532C625.786 13.3187 628.317 12.712 631.159 12.712C634.799 12.712 637.833 13.6653 640.259 15.572C642.721 17.4787 644.333 20.1653 645.095 23.632H635.631C634.834 21.4133 633.291 20.304 631.003 20.304C629.374 20.304 628.074 20.9453 627.103 22.228C626.133 23.476 625.647 25.2787 625.647 27.636C625.647 29.9933 626.133 31.8133 627.103 33.096C628.074 34.344 629.374 34.968 631.003 34.968C633.291 34.968 634.834 33.8587 635.631 31.64H645.095C644.333 35.0373 642.721 37.7067 640.259 39.648C637.798 41.5893 634.765 42.56 631.159 42.56C628.317 42.56 625.786 41.9533 623.567 40.74C621.383 39.5267 619.667 37.7933 618.419 35.54C617.206 33.2867 616.599 30.652 616.599 27.636Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M667.584 42.144L658.744 29.976V42.144H649.852V3.66401H658.744V24.932L667.532 13.128H678.504L666.44 27.688L678.608 42.144H667.584Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M686.156 42.56C684.596 42.56 683.314 42.1093 682.308 41.208C681.338 40.272 680.852 39.128 680.852 37.776C680.852 36.3893 681.338 35.228 682.308 34.292C683.314 33.356 684.596 32.888 686.156 32.888C687.682 32.888 688.93 33.356 689.9 34.292C690.906 35.228 691.408 36.3893 691.408 37.776C691.408 39.128 690.906 40.272 689.9 41.208C688.93 42.1093 687.682 42.56 686.156 42.56Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default DeveloperAnimationEs;
