import './NameStrokeFr.scss';

const NameStrokeFr = () => (
  <div className='NameStrokeFr'>
    <svg
      id='nameFr'
      viewBox='0 0 505 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.9 8.49602C18.748 8.49602 22.1107 9.25869 24.988 10.784C27.8653 12.3094 30.084 14.4587 31.644 17.232C33.2387 19.9707 34.036 23.1427 34.036 26.748C34.036 30.3187 33.2387 33.4907 31.644 36.264C30.084 39.0374 27.848 41.1867 24.936 42.712C22.0587 44.2374 18.7133 45 14.9 45H1.224V8.49602H14.9ZM14.328 37.304C17.6907 37.304 20.308 36.3854 22.18 34.548C24.052 32.7107 24.988 30.1107 24.988 26.748C24.988 23.3854 24.052 20.768 22.18 18.896C20.308 17.024 17.6907 16.088 14.328 16.088H10.116V37.304H14.328Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M43.4772 12.968C41.9172 12.968 40.6346 12.5174 39.6292 11.616C38.6586 10.68 38.1732 9.53602 38.1732 8.18402C38.1732 6.79735 38.6586 5.65335 39.6292 4.75202C40.6346 3.81602 41.9172 3.34802 43.4772 3.34802C45.0026 3.34802 46.2506 3.81602 47.2213 4.75202C48.2266 5.65335 48.7293 6.79735 48.7293 8.18402C48.7293 9.53602 48.2266 10.68 47.2213 11.616C46.2506 12.5174 45.0026 12.968 43.4772 12.968ZM47.8973 15.984V45H39.0052V15.984H47.8973Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M63.2332 6.52002V45H54.3412V6.52002H63.2332Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M99.3171 15.984L81.1171 58.78H71.5491L78.2051 44.012L66.4011 15.984H76.3331L83.0411 34.132L89.6971 15.984H99.3171Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M100.765 30.44C100.765 27.4587 101.319 24.8414 102.429 22.588C103.573 20.3347 105.115 18.6014 107.057 17.388C108.998 16.1747 111.165 15.568 113.557 15.568C115.602 15.568 117.387 15.984 118.913 16.816C120.473 17.648 121.669 18.74 122.501 20.092V15.984H131.393V45H122.501V40.892C121.634 42.244 120.421 43.336 118.861 44.168C117.335 45 115.55 45.416 113.505 45.416C111.147 45.416 108.998 44.8094 107.057 43.596C105.115 42.348 103.573 40.5974 102.429 38.344C101.319 36.056 100.765 33.4214 100.765 30.44ZM122.501 30.492C122.501 28.2734 121.877 26.5227 120.629 25.24C119.415 23.9574 117.925 23.316 116.157 23.316C114.389 23.316 112.881 23.9574 111.633 25.24C110.419 26.488 109.813 28.2214 109.813 30.44C109.813 32.6587 110.419 34.4267 111.633 35.744C112.881 37.0267 114.389 37.668 116.157 37.668C117.925 37.668 119.415 37.0267 120.629 35.744C121.877 34.4614 122.501 32.7107 122.501 30.492Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M155.506 15.672C158.903 15.672 161.607 16.7814 163.618 19C165.663 21.184 166.686 24.2 166.686 28.048V45H157.846V29.244C157.846 27.3027 157.343 25.7947 156.338 24.72C155.332 23.6454 153.98 23.108 152.282 23.108C150.583 23.108 149.231 23.6454 148.226 24.72C147.22 25.7947 146.718 27.3027 146.718 29.244V45H137.826V15.984H146.718V19.832C147.619 18.5494 148.832 17.544 150.358 16.816C151.883 16.0534 153.599 15.672 155.506 15.672Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M171.097 30.44C171.097 27.4587 171.651 24.8414 172.761 22.588C173.905 20.3347 175.447 18.6014 177.389 17.388C179.33 16.1747 181.497 15.568 183.889 15.568C185.934 15.568 187.719 15.984 189.245 16.816C190.805 17.648 192.001 18.74 192.833 20.092V15.984H201.725V45H192.833V40.892C191.966 42.244 190.753 43.336 189.193 44.168C187.667 45 185.882 45.416 183.837 45.416C181.479 45.416 179.33 44.8094 177.389 43.596C175.447 42.348 173.905 40.5974 172.761 38.344C171.651 36.056 171.097 33.4214 171.097 30.44ZM192.833 30.492C192.833 28.2734 192.209 26.5227 190.961 25.24C189.747 23.9574 188.257 23.316 186.489 23.316C184.721 23.316 183.213 23.9574 181.965 25.24C180.751 26.488 180.145 28.2214 180.145 30.44C180.145 32.6587 180.751 34.4267 181.965 35.744C183.213 37.0267 184.721 37.668 186.489 37.668C188.257 37.668 189.747 37.0267 190.961 35.744C192.209 34.4614 192.833 32.7107 192.833 30.492Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M232.385 45.364C229.716 45.364 227.324 44.9307 225.209 44.064C223.094 43.1974 221.396 41.9147 220.113 40.216C218.865 38.5174 218.206 36.472 218.137 34.08H227.601C227.74 35.432 228.208 36.472 229.005 37.2C229.802 37.8934 230.842 38.24 232.125 38.24C233.442 38.24 234.482 37.9454 235.245 37.356C236.008 36.732 236.389 35.8827 236.389 34.808C236.389 33.9067 236.077 33.1614 235.453 32.572C234.864 31.9827 234.118 31.4974 233.217 31.116C232.35 30.7347 231.102 30.3014 229.473 29.816C227.116 29.088 225.192 28.36 223.701 27.632C222.21 26.904 220.928 25.8294 219.853 24.408C218.778 22.9867 218.241 21.132 218.241 18.844C218.241 15.4467 219.472 12.7947 221.933 10.888C224.394 8.94669 227.601 7.97602 231.553 7.97602C235.574 7.97602 238.816 8.94669 241.277 10.888C243.738 12.7947 245.056 15.464 245.229 18.896H235.609C235.54 17.7174 235.106 16.7987 234.309 16.14C233.512 15.4467 232.489 15.1 231.241 15.1C230.166 15.1 229.3 15.3947 228.641 15.984C227.982 16.5387 227.653 17.3534 227.653 18.428C227.653 19.6067 228.208 20.5254 229.317 21.184C230.426 21.8427 232.16 22.5534 234.517 23.316C236.874 24.1134 238.781 24.876 240.237 25.604C241.728 26.332 243.01 27.3894 244.085 28.776C245.16 30.1627 245.697 31.948 245.697 34.132C245.697 36.212 245.16 38.1014 244.085 39.8C243.045 41.4987 241.52 42.8507 239.509 43.856C237.498 44.8614 235.124 45.364 232.385 45.364Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M267.341 37.46V45H262.817C259.593 45 257.08 44.22 255.277 42.66C253.475 41.0654 252.573 38.4827 252.573 34.912V23.368H249.037V15.984H252.573V8.91202H261.465V15.984H267.289V23.368H261.465V35.016C261.465 35.8827 261.673 36.5067 262.089 36.888C262.505 37.2694 263.199 37.46 264.169 37.46H267.341Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M299.646 30.024C299.646 30.856 299.594 31.7227 299.49 32.624H279.366C279.505 34.4267 280.077 35.8134 281.082 36.784C282.122 37.72 283.388 38.188 284.878 38.188C287.097 38.188 288.64 37.252 289.506 35.38H298.97C298.485 37.2867 297.601 39.0027 296.318 40.528C295.07 42.0534 293.493 43.2494 291.586 44.116C289.68 44.9827 287.548 45.416 285.19 45.416C282.348 45.416 279.817 44.8094 277.598 43.596C275.38 42.3827 273.646 40.6494 272.398 38.396C271.15 36.1427 270.526 33.508 270.526 30.492C270.526 27.476 271.133 24.8414 272.346 22.588C273.594 20.3347 275.328 18.6014 277.546 17.388C279.765 16.1747 282.313 15.568 285.19 15.568C287.998 15.568 290.494 16.1574 292.678 17.336C294.862 18.5147 296.561 20.196 297.774 22.38C299.022 24.564 299.646 27.112 299.646 30.024ZM290.546 27.684C290.546 26.1587 290.026 24.9454 288.986 24.044C287.946 23.1427 286.646 22.692 285.086 22.692C283.596 22.692 282.33 23.1254 281.29 23.992C280.285 24.8587 279.661 26.0894 279.418 27.684H290.546ZM291.17 8.54802L278.638 13.8V7.66402L291.17 1.63202V8.54802Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M318.897 23.368H314.113V45H305.221V23.368H301.997V15.984H305.221V15.152C305.221 11.5814 306.244 8.87735 308.289 7.04002C310.335 5.16802 313.333 4.23202 317.285 4.23202C317.944 4.23202 318.429 4.24935 318.741 4.28402V11.824C317.043 11.72 315.847 11.9627 315.153 12.552C314.46 13.1414 314.113 14.1987 314.113 15.724V15.984H318.897V23.368Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M321.308 30.44C321.308 27.4587 321.862 24.8414 322.972 22.588C324.116 20.3347 325.658 18.6014 327.6 17.388C329.541 16.1747 331.708 15.568 334.1 15.568C336.145 15.568 337.93 15.984 339.456 16.816C341.016 17.648 342.212 18.74 343.044 20.092V15.984H351.936V45H343.044V40.892C342.177 42.244 340.964 43.336 339.404 44.168C337.878 45 336.093 45.416 334.048 45.416C331.69 45.416 329.541 44.8094 327.6 43.596C325.658 42.348 324.116 40.5974 322.972 38.344C321.862 36.056 321.308 33.4214 321.308 30.44ZM343.044 30.492C343.044 28.2734 342.42 26.5227 341.172 25.24C339.958 23.9574 338.468 23.316 336.7 23.316C334.932 23.316 333.424 23.9574 332.176 25.24C330.962 26.488 330.356 28.2214 330.356 30.44C330.356 32.6587 330.962 34.4267 332.176 35.744C333.424 37.0267 334.932 37.668 336.7 37.668C338.468 37.668 339.958 37.0267 341.172 35.744C342.42 34.4614 343.044 32.7107 343.044 30.492Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M376.049 15.672C379.446 15.672 382.15 16.7814 384.161 19C386.206 21.184 387.229 24.2 387.229 28.048V45H378.389V29.244C378.389 27.3027 377.886 25.7947 376.881 24.72C375.875 23.6454 374.523 23.108 372.825 23.108C371.126 23.108 369.774 23.6454 368.769 24.72C367.763 25.7947 367.261 27.3027 367.261 29.244V45H358.369V15.984H367.261V19.832C368.162 18.5494 369.375 17.544 370.901 16.816C372.426 16.0534 374.142 15.672 376.049 15.672Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M406.616 45.416C403.773 45.416 401.208 44.8094 398.92 43.596C396.666 42.3827 394.881 40.6494 393.564 38.396C392.281 36.1427 391.64 33.508 391.64 30.492C391.64 27.5107 392.298 24.8934 393.616 22.64C394.933 20.352 396.736 18.6014 399.024 17.388C401.312 16.1747 403.877 15.568 406.72 15.568C409.562 15.568 412.128 16.1747 414.416 17.388C416.704 18.6014 418.506 20.352 419.824 22.64C421.141 24.8934 421.8 27.5107 421.8 30.492C421.8 33.4734 421.124 36.108 419.772 38.396C418.454 40.6494 416.634 42.3827 414.312 43.596C412.024 44.8094 409.458 45.416 406.616 45.416ZM406.616 37.72C408.314 37.72 409.753 37.096 410.932 35.848C412.145 34.6 412.752 32.8147 412.752 30.492C412.752 28.1694 412.162 26.384 410.984 25.136C409.84 23.888 408.418 23.264 406.72 23.264C404.986 23.264 403.548 23.888 402.404 25.136C401.26 26.3494 400.688 28.1347 400.688 30.492C400.688 32.8147 401.242 34.6 402.352 35.848C403.496 37.096 404.917 37.72 406.616 37.72Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M439.569 36.68L445.861 15.984H455.325L445.029 45H434.057L423.761 15.984H433.277L439.569 36.68Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M457.3 30.44C457.3 27.4587 457.854 24.8414 458.964 22.588C460.108 20.3347 461.65 18.6014 463.592 17.388C465.533 16.1747 467.7 15.568 470.092 15.568C472.137 15.568 473.922 15.984 475.448 16.816C477.008 17.648 478.204 18.74 479.036 20.092V15.984H487.928V45H479.036V40.892C478.169 42.244 476.956 43.336 475.396 44.168C473.87 45 472.085 45.416 470.04 45.416C467.682 45.416 465.533 44.8094 463.592 43.596C461.65 42.348 460.108 40.5974 458.964 38.344C457.854 36.056 457.3 33.4214 457.3 30.44ZM479.036 30.492C479.036 28.2734 478.412 26.5227 477.164 25.24C475.95 23.9574 474.46 23.316 472.692 23.316C470.924 23.316 469.416 23.9574 468.168 25.24C466.954 26.488 466.348 28.2214 466.348 30.44C466.348 32.6587 466.954 34.4267 468.168 35.744C469.416 37.0267 470.924 37.668 472.692 37.668C474.46 37.668 475.95 37.0267 477.164 35.744C478.412 34.4614 479.036 32.7107 479.036 30.492Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
      <path
        d='M498.521 45.416C496.961 45.416 495.678 44.9654 494.673 44.064C493.702 43.128 493.217 41.984 493.217 40.632C493.217 39.2454 493.702 38.084 494.673 37.148C495.678 36.212 496.961 35.744 498.521 35.744C500.046 35.744 501.294 36.212 502.265 37.148C503.27 38.084 503.773 39.2454 503.773 40.632C503.773 41.984 503.27 43.128 502.265 44.064C501.294 44.9654 500.046 45.416 498.521 45.416Z'
        stroke='#F5497D'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default NameStrokeFr;
