import './DeveloperAnimationFr.scss';

const DeveloperAnimationFr = () => (
  <div className='DeveloperAnimationFr'>
    <svg
      id='developerFr'
      width='623'
      height='56'
      viewBox='0 0 623 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 28.2127C1 25.4503 1.51394 23.0252 2.54182 20.9373C3.60182 18.8494 5.03121 17.2434 6.83 16.1191C8.62879 14.9949 10.6364 14.4327 12.8527 14.4327C14.6194 14.4327 16.2255 14.8021 17.6709 15.5409C19.1485 16.2797 20.3048 17.2755 21.14 18.5282V6.04911H29.3791V41.7037H21.14V37.8491C20.3691 39.134 19.2609 40.1618 17.8155 40.9327C16.4021 41.7037 14.7479 42.0891 12.8527 42.0891C10.6364 42.0891 8.62879 41.527 6.83 40.4027C5.03121 39.2464 3.60182 37.6243 2.54182 35.5364C1.51394 33.4164 1 30.9752 1 28.2127ZM21.14 28.2609C21.14 26.2052 20.5618 24.583 19.4055 23.3946C18.2812 22.2061 16.9 21.6118 15.2618 21.6118C13.6236 21.6118 12.2264 22.2061 11.07 23.3946C9.94576 24.5509 9.38364 26.157 9.38364 28.2127C9.38364 30.2685 9.94576 31.9067 11.07 33.1273C12.2264 34.3158 13.6236 34.91 15.2618 34.91C16.9 34.91 18.2812 34.3158 19.4055 33.1273C20.5618 31.9388 21.14 30.3167 21.14 28.2609Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M60.6833 27.8273C60.6833 28.5982 60.6352 29.4012 60.5388 30.2364H41.8924C42.0209 31.9067 42.5509 33.1915 43.4824 34.0909C44.4461 34.9582 45.6185 35.3918 46.9997 35.3918C49.0555 35.3918 50.4849 34.5246 51.2879 32.79H60.057C59.6073 34.5567 58.7882 36.1467 57.5997 37.56C56.4433 38.9734 54.9818 40.0815 53.2152 40.8846C51.4485 41.6876 49.473 42.0891 47.2888 42.0891C44.6549 42.0891 42.31 41.527 40.2542 40.4027C38.1985 39.2785 36.5924 37.6724 35.4361 35.5846C34.2797 33.4967 33.7015 31.0555 33.7015 28.2609C33.7015 25.4664 34.2636 23.0252 35.3879 20.9373C36.5442 18.8494 38.1503 17.2434 40.2061 16.1191C42.2618 14.9949 44.6227 14.4327 47.2888 14.4327C49.8906 14.4327 52.2033 14.9788 54.227 16.0709C56.2506 17.1631 57.8246 18.7209 58.9488 20.7446C60.1052 22.7682 60.6833 25.1291 60.6833 27.8273ZM52.2515 25.6591C52.2515 24.2458 51.7697 23.1215 50.8061 22.2864C49.8424 21.4512 48.6379 21.0337 47.1924 21.0337C45.8112 21.0337 44.6388 21.4352 43.6752 22.2382C42.7436 23.0412 42.1655 24.1815 41.9406 25.6591H52.2515ZM52.8297 7.9282L41.2179 12.7946V7.10911L52.8297 1.52002V7.9282Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M77.1235 33.9946L82.9535 14.8182H91.7226L82.1826 41.7037H72.0162L62.4762 14.8182H71.2935L77.1235 33.9946Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M120.534 27.8273C120.534 28.5982 120.486 29.4012 120.39 30.2364H101.743C101.872 31.9067 102.402 33.1915 103.333 34.0909C104.297 34.9582 105.469 35.3918 106.851 35.3918C108.906 35.3918 110.336 34.5246 111.139 32.79H119.908C119.458 34.5567 118.639 36.1467 117.451 37.56C116.294 38.9734 114.833 40.0815 113.066 40.8846C111.299 41.6876 109.324 42.0891 107.14 42.0891C104.506 42.0891 102.161 41.527 100.105 40.4027C98.0493 39.2785 96.4433 37.6724 95.2869 35.5846C94.1306 33.4967 93.5524 31.0555 93.5524 28.2609C93.5524 25.4664 94.1145 23.0252 95.2387 20.9373C96.3951 18.8494 98.0012 17.2434 100.057 16.1191C102.113 14.9949 104.474 14.4327 107.14 14.4327C109.741 14.4327 112.054 14.9788 114.078 16.0709C116.101 17.1631 117.675 18.7209 118.8 20.7446C119.956 22.7682 120.534 25.1291 120.534 27.8273ZM112.102 25.6591C112.102 24.2458 111.621 23.1215 110.657 22.2864C109.693 21.4512 108.489 21.0337 107.043 21.0337C105.662 21.0337 104.49 21.4352 103.526 22.2382C102.594 23.0412 102.016 24.1815 101.791 25.6591H112.102Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M133.12 6.04911V41.7037H124.881V6.04911H133.12Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M151.329 42.0891C148.695 42.0891 146.318 41.527 144.198 40.4027C142.11 39.2785 140.456 37.6724 139.235 35.5846C138.047 33.4967 137.452 31.0555 137.452 28.2609C137.452 25.4985 138.063 23.0734 139.283 20.9855C140.504 18.8655 142.174 17.2434 144.294 16.1191C146.414 14.9949 148.791 14.4327 151.425 14.4327C154.059 14.4327 156.436 14.9949 158.556 16.1191C160.676 17.2434 162.346 18.8655 163.567 20.9855C164.788 23.0734 165.398 25.4985 165.398 28.2609C165.398 31.0234 164.771 33.4646 163.519 35.5846C162.298 37.6724 160.612 39.2785 158.46 40.4027C156.34 41.527 153.963 42.0891 151.329 42.0891ZM151.329 34.9582C152.903 34.9582 154.236 34.38 155.328 33.2237C156.452 32.0673 157.014 30.4131 157.014 28.2609C157.014 26.1088 156.468 24.4546 155.376 23.2982C154.316 22.1418 152.999 21.5637 151.425 21.5637C149.819 21.5637 148.486 22.1418 147.426 23.2982C146.366 24.4224 145.836 26.0767 145.836 28.2609C145.836 30.4131 146.35 32.0673 147.378 33.2237C148.438 34.38 149.755 34.9582 151.329 34.9582Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M178.008 18.6246C178.811 17.3718 179.919 16.36 181.332 15.5891C182.746 14.8182 184.4 14.4327 186.295 14.4327C188.512 14.4327 190.519 14.9949 192.318 16.1191C194.117 17.2434 195.53 18.8494 196.558 20.9373C197.618 23.0252 198.148 25.4503 198.148 28.2127C198.148 30.9752 197.618 33.4164 196.558 35.5364C195.53 37.6243 194.117 39.2464 192.318 40.4027C190.519 41.527 188.512 42.0891 186.295 42.0891C184.432 42.0891 182.778 41.7037 181.332 40.9327C179.919 40.1618 178.811 39.1661 178.008 37.9455V54.52H169.769V14.8182H178.008V18.6246ZM189.764 28.2127C189.764 26.157 189.186 24.5509 188.03 23.3946C186.906 22.2061 185.508 21.6118 183.838 21.6118C182.2 21.6118 180.802 22.2061 179.646 23.3946C178.522 24.583 177.96 26.2052 177.96 28.2609C177.96 30.3167 178.522 31.9388 179.646 33.1273C180.802 34.3158 182.2 34.91 183.838 34.91C185.476 34.91 186.873 34.3158 188.03 33.1273C189.186 31.9067 189.764 30.2685 189.764 28.2127Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M210.709 18.6246C211.512 17.3718 212.621 16.36 214.034 15.5891C215.447 14.8182 217.102 14.4327 218.997 14.4327C221.213 14.4327 223.221 14.9949 225.019 16.1191C226.818 17.2434 228.232 18.8494 229.259 20.9373C230.319 23.0252 230.849 25.4503 230.849 28.2127C230.849 30.9752 230.319 33.4164 229.259 35.5364C228.232 37.6243 226.818 39.2464 225.019 40.4027C223.221 41.527 221.213 42.0891 218.997 42.0891C217.134 42.0891 215.479 41.7037 214.034 40.9327C212.621 40.1618 211.512 39.1661 210.709 37.9455V54.52H202.47V14.8182H210.709V18.6246ZM222.466 28.2127C222.466 26.157 221.888 24.5509 220.731 23.3946C219.607 22.2061 218.21 21.6118 216.539 21.6118C214.901 21.6118 213.504 22.2061 212.348 23.3946C211.223 24.583 210.661 26.2052 210.661 28.2609C210.661 30.3167 211.223 31.9388 212.348 33.1273C213.504 34.3158 214.901 34.91 216.539 34.91C218.178 34.91 219.575 34.3158 220.731 33.1273C221.888 31.9067 222.466 30.2685 222.466 28.2127Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M260.516 27.8273C260.516 28.5982 260.467 29.4012 260.371 30.2364H241.725C241.853 31.9067 242.383 33.1915 243.315 34.0909C244.278 34.9582 245.451 35.3918 246.832 35.3918C248.888 35.3918 250.317 34.5246 251.12 32.79H259.889C259.439 34.5567 258.62 36.1467 257.432 37.56C256.276 38.9734 254.814 40.0815 253.047 40.8846C251.281 41.6876 249.305 42.0891 247.121 42.0891C244.487 42.0891 242.142 41.527 240.086 40.4027C238.031 39.2785 236.425 37.6724 235.268 35.5846C234.112 33.4967 233.534 31.0555 233.534 28.2609C233.534 25.4664 234.096 23.0252 235.22 20.9373C236.376 18.8494 237.983 17.2434 240.038 16.1191C242.094 14.9949 244.455 14.4327 247.121 14.4327C249.723 14.4327 252.036 14.9788 254.059 16.0709C256.083 17.1631 257.657 18.7209 258.781 20.7446C259.937 22.7682 260.516 25.1291 260.516 27.8273ZM252.084 25.6591C252.084 24.2458 251.602 23.1215 250.638 22.2864C249.675 21.4512 248.47 21.0337 247.025 21.0337C245.643 21.0337 244.471 21.4352 243.507 22.2382C242.576 23.0412 241.998 24.1815 241.773 25.6591H252.084Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M291.41 14.8182V41.7037H283.171V38.0418C282.336 39.2303 281.196 40.194 279.75 40.9327C278.337 41.6394 276.763 41.9927 275.028 41.9927C272.973 41.9927 271.158 41.543 269.584 40.6437C268.01 39.7121 266.789 38.3791 265.922 36.6446C265.055 34.91 264.621 32.8703 264.621 30.5255V14.8182H272.812V29.4173C272.812 31.2161 273.278 32.6134 274.209 33.6091C275.141 34.6049 276.394 35.1027 277.968 35.1027C279.574 35.1027 280.842 34.6049 281.774 33.6091C282.705 32.6134 283.171 31.2161 283.171 29.4173V14.8182H291.41Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M308.266 42.0891C305.921 42.0891 303.833 41.6876 302.002 40.8846C300.171 40.0815 298.726 38.9894 297.666 37.6082C296.606 36.1949 296.011 34.6209 295.883 32.8864H304.026C304.122 33.8179 304.556 34.5727 305.326 35.1509C306.097 35.7291 307.045 36.0182 308.169 36.0182C309.197 36.0182 309.984 35.8255 310.53 35.44C311.108 35.0224 311.397 34.4924 311.397 33.85C311.397 33.0791 310.996 32.517 310.193 32.1637C309.39 31.7782 308.089 31.3606 306.29 30.9109C304.363 30.4612 302.757 29.9955 301.472 29.5137C300.187 28.9997 299.079 28.2127 298.147 27.1527C297.216 26.0606 296.75 24.5991 296.75 22.7682C296.75 21.2264 297.168 19.8291 298.003 18.5764C298.87 17.2915 300.123 16.2797 301.761 15.5409C303.431 14.8021 305.407 14.4327 307.687 14.4327C311.06 14.4327 313.71 15.2679 315.637 16.9382C317.597 18.6085 318.721 20.8249 319.01 23.5873H311.397C311.269 22.6558 310.851 21.917 310.145 21.3709C309.47 20.8249 308.571 20.5518 307.447 20.5518C306.483 20.5518 305.744 20.7446 305.23 21.13C304.716 21.4834 304.459 21.9812 304.459 22.6237C304.459 23.3946 304.861 23.9727 305.664 24.3582C306.499 24.7437 307.784 25.1291 309.518 25.5146C311.51 26.0285 313.132 26.5424 314.385 27.0564C315.637 27.5382 316.73 28.3412 317.661 29.4655C318.625 30.5576 319.123 32.0352 319.155 33.8982C319.155 35.4721 318.705 36.8855 317.806 38.1382C316.938 39.3588 315.67 40.3224 313.999 41.0291C312.361 41.7358 310.45 42.0891 308.266 42.0891Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M349.539 27.8273C349.539 28.5982 349.491 29.4012 349.394 30.2364H330.748C330.877 31.9067 331.407 33.1915 332.338 34.0909C333.302 34.9582 334.474 35.3918 335.855 35.3918C337.911 35.3918 339.34 34.5246 340.144 32.79H348.913C348.463 34.5567 347.644 36.1467 346.455 37.56C345.299 38.9734 343.837 40.0815 342.071 40.8846C340.304 41.6876 338.329 42.0891 336.144 42.0891C333.51 42.0891 331.166 41.527 329.11 40.4027C327.054 39.2785 325.448 37.6724 324.292 35.5846C323.135 33.4967 322.557 31.0555 322.557 28.2609C322.557 25.4664 323.119 23.0252 324.243 20.9373C325.4 18.8494 327.006 17.2434 329.062 16.1191C331.117 14.9949 333.478 14.4327 336.144 14.4327C338.746 14.4327 341.059 14.9788 343.083 16.0709C345.106 17.1631 346.68 18.7209 347.804 20.7446C348.961 22.7682 349.539 25.1291 349.539 27.8273ZM341.107 25.6591C341.107 24.2458 340.625 23.1215 339.662 22.2864C338.698 21.4512 337.493 21.0337 336.048 21.0337C334.667 21.0337 333.494 21.4352 332.531 22.2382C331.599 23.0412 331.021 24.1815 330.796 25.6591H341.107Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M381.973 21.66H377.54V41.7037H369.301V21.66H366.314V14.8182H369.301V14.0473C369.301 10.7388 370.249 8.23335 372.144 6.53093C374.039 4.79638 376.818 3.92911 380.48 3.92911C381.09 3.92911 381.539 3.94517 381.829 3.97729V10.9637C380.255 10.8673 379.146 11.0921 378.504 11.6382C377.862 12.1843 377.54 13.164 377.54 14.5773V14.8182H381.973V21.66Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M412.393 14.8182V41.7037H404.154V38.0418C403.319 39.2303 402.178 40.194 400.733 40.9327C399.319 41.6394 397.746 41.9927 396.011 41.9927C393.955 41.9927 392.14 41.543 390.566 40.6437C388.993 39.7121 387.772 38.3791 386.905 36.6446C386.037 34.91 385.604 32.8703 385.604 30.5255V14.8182H393.795V29.4173C393.795 31.2161 394.26 32.6134 395.192 33.6091C396.123 34.6049 397.376 35.1027 398.95 35.1027C400.556 35.1027 401.825 34.6049 402.756 33.6091C403.688 32.6134 404.154 31.2161 404.154 29.4173V14.8182H412.393Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M426.55 6.04911V41.7037H418.311V6.04911H426.55Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M440.76 6.04911V41.7037H432.521V6.04911H440.76Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M467.449 21.3709V28.2127H446.249V21.3709H467.449Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M485.617 42.0891C483.272 42.0891 481.184 41.6876 479.354 40.8846C477.523 40.0815 476.077 38.9894 475.017 37.6082C473.957 36.1949 473.363 34.6209 473.234 32.8864H481.377C481.474 33.8179 481.907 34.5727 482.678 35.1509C483.449 35.7291 484.397 36.0182 485.521 36.0182C486.549 36.0182 487.336 35.8255 487.882 35.44C488.46 35.0224 488.749 34.4924 488.749 33.85C488.749 33.0791 488.347 32.517 487.544 32.1637C486.741 31.7782 485.44 31.3606 483.642 30.9109C481.714 30.4612 480.108 29.9955 478.823 29.5137C477.539 28.9997 476.43 28.2127 475.499 27.1527C474.567 26.0606 474.102 24.5991 474.102 22.7682C474.102 21.2264 474.519 19.8291 475.354 18.5764C476.222 17.2915 477.474 16.2797 479.113 15.5409C480.783 14.8021 482.758 14.4327 485.039 14.4327C488.412 14.4327 491.062 15.2679 492.989 16.9382C494.948 18.6085 496.073 20.8249 496.362 23.5873H488.749C488.62 22.6558 488.203 21.917 487.496 21.3709C486.822 20.8249 485.922 20.5518 484.798 20.5518C483.834 20.5518 483.096 20.7446 482.582 21.13C482.068 21.4834 481.811 21.9812 481.811 22.6237C481.811 23.3946 482.212 23.9727 483.015 24.3582C483.85 24.7437 485.135 25.1291 486.87 25.5146C488.861 26.0285 490.483 26.5424 491.736 27.0564C492.989 27.5382 494.081 28.3412 495.013 29.4655C495.976 30.5576 496.474 32.0352 496.506 33.8982C496.506 35.4721 496.056 36.8855 495.157 38.1382C494.29 39.3588 493.021 40.3224 491.351 41.0291C489.713 41.7358 487.801 42.0891 485.617 42.0891Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M516.531 34.7173V41.7037H512.34C509.352 41.7037 507.024 40.9809 505.353 39.5355C503.683 38.0579 502.848 35.6649 502.848 32.3564V21.66H499.571V14.8182H502.848V8.26547H511.087V14.8182H516.483V21.66H511.087V32.4527C511.087 33.2558 511.28 33.834 511.665 34.1873C512.05 34.5406 512.693 34.7173 513.592 34.7173H516.531Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M519.483 28.2127C519.483 25.4503 519.996 23.0252 521.024 20.9373C522.084 18.8494 523.514 17.2434 525.312 16.1191C527.111 14.9949 529.119 14.4327 531.335 14.4327C533.23 14.4327 534.885 14.8182 536.298 15.5891C537.743 16.36 538.852 17.3718 539.623 18.6246V14.8182H547.862V41.7037H539.623V37.8973C538.82 39.15 537.695 40.1618 536.25 40.9327C534.836 41.7037 533.182 42.0891 531.287 42.0891C529.103 42.0891 527.111 41.527 525.312 40.4027C523.514 39.2464 522.084 37.6243 521.024 35.5364C519.996 33.4164 519.483 30.9752 519.483 28.2127ZM539.623 28.2609C539.623 26.2052 539.044 24.583 537.888 23.3946C536.764 22.2061 535.383 21.6118 533.744 21.6118C532.106 21.6118 530.709 22.2061 529.552 23.3946C528.428 24.5509 527.866 26.157 527.866 28.2127C527.866 30.2685 528.428 31.9067 529.552 33.1273C530.709 34.3158 532.106 34.91 533.744 34.91C535.383 34.91 536.764 34.3158 537.888 33.1273C539.044 31.9388 539.623 30.3167 539.623 28.2609Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M552.184 28.2609C552.184 25.4664 552.746 23.0252 553.87 20.9373C555.027 18.8494 556.617 17.2434 558.64 16.1191C560.696 14.9949 563.041 14.4327 565.675 14.4327C569.048 14.4327 571.858 15.3161 574.107 17.0827C576.387 18.8494 577.881 21.3388 578.588 24.5509H569.819C569.08 22.4952 567.65 21.4673 565.53 21.4673C564.021 21.4673 562.816 22.0615 561.917 23.25C561.017 24.4064 560.568 26.0767 560.568 28.2609C560.568 30.4452 561.017 32.1315 561.917 33.32C562.816 34.4764 564.021 35.0546 565.53 35.0546C567.65 35.0546 569.08 34.0267 569.819 31.9709H578.588C577.881 35.1188 576.387 37.5921 574.107 39.3909C571.826 41.1897 569.016 42.0891 565.675 42.0891C563.041 42.0891 560.696 41.527 558.64 40.4027C556.617 39.2785 555.027 37.6724 553.87 35.5846C552.746 33.4967 552.184 31.0555 552.184 28.2609Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M599.425 41.7037L591.234 30.4291V41.7037H582.995V6.04911H591.234V25.7555L599.377 14.8182H609.543L598.365 28.3091L609.639 41.7037H599.425Z'
        stroke='white'
        strokeWidth='1.5'
      />
      <path
        d='M616.634 42.0891C615.188 42.0891 614 41.6715 613.068 40.8364C612.169 39.9691 611.719 38.9091 611.719 37.6564C611.719 36.3715 612.169 35.2955 613.068 34.4282C614 33.5609 615.188 33.1273 616.634 33.1273C618.047 33.1273 619.203 33.5609 620.103 34.4282C621.034 35.2955 621.5 36.3715 621.5 37.6564C621.5 38.9091 621.034 39.9691 620.103 40.8364C619.203 41.6715 618.047 42.0891 616.634 42.0891Z'
        stroke='white'
        strokeWidth='1.5'
      />
    </svg>
  </div>
);

export default DeveloperAnimationFr;
