import { LOCALES } from '../constants';

const fr = {
  [LOCALES.FRENCH]: {
    menu: {
      home: 'Accueil',
      about: 'À propos',
      skills: 'Compétences',
      projects: 'Projets',
      experience: 'Expérience',
      contact: 'Contact',
      resume: 'CV',
    },
    home: {
      presentation: "Bonjour ! Je m'appelle ",
      profession: 'Je suis ',
      contactBtn: 'Contactez-moi',
    },
    about: {
      heading: 'À propos de moi',
      presentation:
        "Bonjour ! 👋🏻 Je m'appelle Dilyana et je suis développeuse full-stack, basée sur Nice, France.",
      info1:
        "Je suis passionnée par la programmation 💻 et les langues étrangères 🗺️ . J'aime créer des applications web et mobiles esthétiques et j'ai pour objectif d'écrire un code bien structuré et de qualité, ainsi que de m'améliorer en permanence.",
      info2:
        "Je parle courament français 🇫🇷 , anglais 🇬🇧 , espagnol 🇪🇸 et bulgare 🇧🇬. Je suis diplômée d'un Master en",
      mastersDegree: '"Études Hspaniques et Hispano-Américaines" ',
      info3: "et j'ai un Titre Professionnel de ",
      bachelorsDegree: '"Concepteur Développeur d\'Applications."',
      info4:
        'Je me "nourris" principalement de code et de bon café ☕ . Je suis motivée et j\'aime acquerir de nouvelles compétences, adopter de bonnes pratiques 📚 , me tenir à jour des technologies les plus récentes et les mettre en pratique en développant différents projets 👩🏼‍🔬 .',
      interestsHeading: 'Intérêts : ',
      info5:
        'programmation, apprentissage, développement personnel, langues étrangères,  jeux vidéo, retro gaming, jeux de plateau et électronique. Bon, je suis une "geek" 👩🏼‍💻 .',
    },
    skills: {
      heading: 'Compétences',
    },
    projects: {
      heading: 'Projets',
      technologies: 'Technologies',
      description: 'Description',
      visitSiteBtn: 'Visiter Le Site',
      seeMoreBtn: 'Voir Plus',
      watchDemoBtn: 'Voir Demo',
      checkGithubBtn: 'Voir Github',
    },
    experience: {
      heading: 'Experience',
    },
    contact: {
      heading: 'Contactez-moi',
      form: {
        name: 'Nom et Prénom',
        email: 'Adresse Email',
        subject: 'Sujet',
        message: 'Message',
        sendButton: 'Envoyer',
        confirmation: 'Message Envoyé!',
        error: 'Une erreur est survenue.',
      },
    },
    flags: {
      british: 'drapeau britanique',
      bulgarian: 'drapeau bulgare',
      french: 'drapeau français',
      spanish: 'drapeau espagnol',
    },
  },
};

export default fr;
