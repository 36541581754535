import { LOCALES } from '../constants';

const en = {
  [LOCALES.ENGLISH]: {
    menu: {
      home: 'Home',
      about: 'About',
      skills: 'Skills',
      projects: 'Projects',
      experience: 'Experience',
      contact: 'Contact',
      resume: 'Resume',
    },
    home: {
      presentation: "Hello! I'm ",
      profession: "I'm a ",
      contactBtn: 'Contact me',
    },
    about: {
      heading: 'About me',
      presentation:
        "Hey! 👋🏻 I'm Dilyana and I'm a full-stack developer based in Nice, France.",
      info1:
        "I'm passionate about programming 💻 and foreign languages 🗺️. I love creating aesthetic web and mobile applications and my goal is to write clean, well-structured code and to improve myself constantly.",
      info2:
        "I speak French 🇫🇷 , English 🇬🇧 , Spanish 🇪🇸 and Bulgarian 🇧🇬 fluently. I have a Master's degree with honours in",
      mastersDegree: '"Hispanic and Latin American Studies" ',
      info3: "and a Bachelor's level professional degree in",
      bachelorsDegree:
        '"Full-Stack Web and Mobile Application Engineering".',
      info4:
        "I mainly run on code and good coffee ☕ . I'm highly motivated and passionate about learning new skills and best practices 📚. I also like keeping me up to date with the latest technologies and trying them by building different projects 👩🏼‍🔬 .",
      interestsHeading: 'Interests: ',
      info5:
        "programming, learning, self development, foreign languages, video games, retro gaming consoles, board games and electronics. Well, I'm a geek 👩🏼‍💻 .",
    },
    skills: {
      heading: 'Skills',
    },
    projects: {
      heading: 'Projects',
      technologies: 'Technologies',
      description: 'Description',
      visitSiteBtn: 'Visit Site',
      seeMoreBtn: 'See More',
      watchDemoBtn: 'Watch Demo',
      checkGithubBtn: 'Check Github',
    },
    experience: {
      heading: 'Experience',
    },
    contact: {
      heading: 'Contact Me',
      form: {
        name: 'Full Name',
        email: 'Email Address',
        subject: 'Subject',
        message: 'Message',
        sendButton: 'Send',
        confirmation: 'Email Sent!',
        error: 'An error occurred.',
      },
    },
    flags: {
      british: 'british flag',
      bulgarian: 'bulgarian flag',
      french: 'french flag',
      spanish: 'spanish flag',
    },
  },
};

export default en;
